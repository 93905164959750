// Libs
import { useRef, useState, useEffect } from 'react';
import { useFormik } from 'formik';
// Firebase
import { messaging, getToken } from '../../../firebase/firebaseConfig';
// API Requests
import { updateFCMToken, updateBookingReminders } from '../../../api/Users';
// Material-UI
import { Avatar, Box, Button, FormControl, FormControlLabel, Switch, TextField } from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { createProfileSchema } from '../../../validations/ProfileSchema';

interface ProfileFormProps {
  user: any;
  isEditMode: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  handleUpdateProfile: (data: any) => void;
  handleUpdateProfileImage: (userId: string, file: File) => void;
  showAlert: (message: string, subMessage: string, status: 'success' | 'error') => void;
  updateUserData: (userData: any) => void;
}

export default function ProfileForm({
  user,
  isEditMode,
  setIsEditMode,
  handleUpdateProfile,
  handleUpdateProfileImage,
  showAlert,
  updateUserData
}: ProfileFormProps) {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  // if user.fcm_token is not null, then notifications are enabled
  const [notificationsEnabled, setNotificationsEnabled] = useState(user?.fcm_token !== null);
  // Loading state for notification toggle
  const [isNotificationLoading, setIsNotificationLoading] = useState(false);
  // Booking reminders state
  const [bookingRemindersEnabled, setBookingRemindersEnabled] = useState(user?.booking_reminders || false);

  const handleAvatarClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event: { target: { value: string; files: any[]; }; }) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ['image/jpeg', 'image/png'];
      if (!allowedTypes.includes(file.type)) {
        showAlert('Invalid file type', 'Please upload a JPEG or PNG image.', 'error');
        return;
      }

      // Check file size (e.g., 5MB max)
      const maxSize = 5 * 1024 * 1024; // 5MB in bytes
      if (file.size > maxSize) {
        showAlert('File is too large', 'Max Size 5MB.', 'error');
        return;
      }

      // If the file is valid, set the selectedImage state to the file's URL
      const fileUrl = URL.createObjectURL(file);
      setSelectedImage(fileUrl);
      await handleUpdateProfileImage(user?.id, file);
    }
  };

  useEffect(() => {
    // This function is called when the component is unmounted or the selectedImage changes
    return () => {
      if (selectedImage) {
        URL.revokeObjectURL(selectedImage);
      }
    };
  }, [selectedImage]);

  const formik = useFormik({
    initialValues: {
      userId: user?.id,
      firstName: user?.first_name,
      lastName: user?.last_name,
      clubName: user?.club_name,
      email: user?.email,
      telephone: user?.telephone,
      timezone: user?.timezone,
    },
    validationSchema: createProfileSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values, { resetForm }) => {
      handleUpdateProfile(values);
    },
  });

  const handleEditClick = () => {
    setIsEditMode(!isEditMode);
  };

  const handleToggleBookingReminders = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const enabled = event.target.checked;
    try {
      const response = await updateBookingReminders(user?.id, enabled.toString());
      if (response.success) {
        showAlert(response.success.message, response.success.subMessage, 'success');
        updateUserData(response.user);
        setBookingRemindersEnabled(enabled);
      } else {
        showAlert(response.error?.message, response.error?.subMessage, 'error');
      }
    } catch (error) {
      showAlert('Error', 'Something went wrong. Please try again.', 'error');
    }
  };

  const requestNotificationPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const registration = await navigator.serviceWorker.register("/custom-sw-template.js");

        const token = await getToken(messaging, {
          vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY,
          serviceWorkerRegistration: registration
        });
        return token;
      } else {
        console.log("Notification permission denied");
        showAlert('Notification permission denied', 'Please enable notifications in your browser settings.', 'error');
        return null;
      }
    } catch (error) {
      console.error("Error getting notification permission:", error);
      showAlert('Error', 'Something went wrong while requesting notification permission. Please try again.', 'error');
      return null;
    }
  };

  const updateNotificationSettings = async (enabled: boolean, token: string) => {
    const response = await updateFCMToken(user?.id ?? '', token || '');

    if (response.success) {
      showAlert(response.success.message, response.success.subMessage, 'success');
      updateUserData(response.user); // Update user context
      setNotificationsEnabled(enabled); // Update state
      if (!enabled) {
        await disableBookingReminders();
      }
    } else {
      showAlert(response.error?.message || 'Error', response.error?.subMessage || 'Please try again.', 'error');
    }
  };

  const disableBookingReminders = async () => {
    try {
      const response = await updateBookingReminders(user?.id, 'false');
      if (response.success) {
        updateUserData(response.user); // Update user context
        setBookingRemindersEnabled(false); // Update state
      } else {
        console.error("Error updating booking reminders:", response.error);
      }
    } catch (error) {
      console.error("Error updating booking reminders:", error);
    }
  };

  const handleNotificationError = () => {
    setNotificationsEnabled(false); // Reset the switch if permission is denied
    setBookingRemindersEnabled(false); // Disable booking reminders if notifications are disabled
  };

  const handleToggleNotifications = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const enabled = event.target.checked;
    setIsNotificationLoading(true);

    try {
      const token = enabled ? await requestNotificationPermission() : '';
      if (token !== null) {
        await updateNotificationSettings(enabled, token);
      } else {
        handleNotificationError();
      }
    } catch (error) {
      showAlert('Error', 'Something went wrong. Please try again.', 'error');
    } finally {
      setIsNotificationLoading(false);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box sx={{ mt: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mb: 3, scrollSnapAlign: 'center', }}>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (event.target.files && event.target.files.length > 0) {
                const filesArray = Array.from(event.target.files); // Convert FileList to array
                handleFileChange({ target: { files: filesArray, value: '' } });

                setTimeout(() => {
                  event.target.value = '';
                }, 0);
              }
            }}
            accept="image/*"
          />
          {selectedImage || user?.member_profile_image_url ? (
            <Avatar
              sx={{ m: 1, width: 66, height: 66 }}
              src={selectedImage || user?.member_profile_image_url}
              alt="Profile Picture"
              onClick={handleAvatarClick}
            />
          ) : (
            <Avatar
              sx={{ m: 1, width: 66, height: 66, bgcolor: 'grey.500' }}
              onClick={handleAvatarClick}
            >
              <AddAPhotoIcon />
            </Avatar>
          )}
        </Box>

        {/* First Name */}
        <FormControl fullWidth error={formik.touched.firstName && Boolean(formik.errors.firstName)} sx={{ mb: 2 }}>
          <TextField
            disabled={!isEditMode}
            id="firstName"
            label="First Name"
            name="firstName"
            fullWidth
            required
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          />
        </FormControl>

        {/* Last Name */}
        <FormControl fullWidth error={formik.touched.lastName && Boolean(formik.errors.lastName)} sx={{ mb: 2 }}>
          <TextField
            disabled={!isEditMode}
            id="lastName"
            label="Last Name"
            name="lastName"
            fullWidth
            required
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          />
        </FormControl>

        {/* Club Name */}
        <FormControl fullWidth error={formik.touched.clubName && Boolean(formik.errors.clubName)} sx={{ mb: 2 }}>
          <TextField
            disabled
            id="clubName"
            label="Club Name"
            name="clubName"
            fullWidth
            required
            value={formik.values.clubName}
            onChange={formik.handleChange}
            error={formik.touched.clubName && Boolean(formik.errors.clubName)}
          />
        </FormControl>

        {/* Email */}
        <FormControl fullWidth error={formik.touched.email && Boolean(formik.errors.email)} sx={{ mb: 2 }}>
          <TextField
            disabled={!isEditMode}
            id="email"
            label="Email"
            name="email"
            fullWidth
            required
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
          />
        </FormControl>

        {/* Telephone */}
        <FormControl fullWidth error={formik.touched.telephone && Boolean(formik.errors.telephone)} sx={{ mb: 2 }}>
          <TextField
            disabled={!isEditMode}
            id="telephone"
            label="Telephone"
            name="telephone"
            fullWidth
            required
            value={formik.values.telephone}
            onChange={formik.handleChange}
            error={formik.touched.telephone && Boolean(formik.errors.telephone)}
          />
        </FormControl>

        {/* Timezone */}
        <FormControl fullWidth error={formik.touched.timezone && Boolean(formik.errors.timezone)} sx={{ mb: 2 }}>
          <TextField
            id="timezone"
            label="Timezone"
            name="timezone"
            fullWidth
            required
            disabled
            value={formik.values.timezone}
            onChange={formik.handleChange}
            error={formik.touched.timezone && Boolean(formik.errors.timezone)}
          />
        </FormControl>
        {/* Enable/Disable Notifications */}
        <FormControlLabel
          control={
            <Switch sx={{ marginLeft: 1.5 }}
              checked={notificationsEnabled}
              disabled={isNotificationLoading}
              onChange={handleToggleNotifications}
              name="notifications"
              color="primary"
            />
          }
          label={notificationsEnabled ? "Notifications Enabled" : "Enable Notifications"}
        />
        {/* Enable/Disable Booking Reminders */}
        <FormControlLabel
          control={
            <Switch sx={{ marginLeft: 1.5 }}
              checked={bookingRemindersEnabled}
              disabled={!notificationsEnabled}
              onChange={handleToggleBookingReminders}
              name="bookingReminders"
              color="primary"
            />
          }
          label={notificationsEnabled ? "Booking Reminders Enabled" : "Enable Booking Reminders"}
        />
      </Box>
      {/* Edit/ Save Profile Button */}
      <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
        <Button
          onClick={handleEditClick}
          color={isEditMode ? "error" : "primary"}
          variant="outlined"
          sx={{ mr: 1 }}
        >
          {isEditMode ? "Cancel" : "Edit"}
        </Button>
        <Button
          type="submit"
          color="primary"
          variant="outlined"
          disabled={!isEditMode || formik.isSubmitting}
        >
          Save Profile
        </Button>
      </Box>
    </form>
  );
}