import React, { useState } from 'react';
// Helpers
import { internationalizePhoneNumber } from "../../helpers/Utilities";
// Material UI
import { 
  Avatar, 
  Box, 
  Card, 
  CardContent, 
  IconButton, 
  Grid, 
  Popover, 
  Stack, 
  Typography 
} from '@mui/material';
// Material UI Icons
import GroupIcon from '@mui/icons-material/Group';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import PersonIcon from '@mui/icons-material/Person';

export default function MemberList({member}: {member: any}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'family-members-popover' : undefined;

  return (
    <Grid item xs={12} md={6}>
      <Card 
        key={member.id} 
        sx={{ 
          mb: 2, 
          borderRadius: 2,
          transition: 'all 0.2s',
          borderLeft: '4px solid',
          borderColor: 'primary.main',
        }}
      >
        <CardContent sx={{ p: 2, '&:last-child': { pb: 2 } }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar 
              src={member.member_profile_image_url}
              sx={{ width: 40, height: 40 }}
            >
              {!member.member_profile_image_url && <PersonIcon />}
            </Avatar>
            
            <Box sx={{ ml: 2, flexGrow: 1 }}>
              <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center' }}>
                <Typography variant="body1" fontWeight="500">
                  {member.first_name} {member.last_name}
                </Typography>
                {member.family_members && member.family_members.length > 0 && (
                  <>
                    <IconButton 
                      onClick={handleClick} 
                      size="small" 
                      sx={{ 
                        ml: 1, 
                        bgcolor: 'primary.50' 
                      }}
                    >
                      <GroupIcon fontSize="small" color="primary" />
                    </IconButton>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      <Box sx={{ p: 2 }}>
                        <Typography sx={{ fontWeight: 'bold' }} variant="subtitle1">Family Members</Typography>
                        {member.family_members.map((familyMember: any, index: number) => (
                          <Typography key={index}>{familyMember.first_name} {familyMember.last_name}</Typography>
                        ))}
                      </Box>
                    </Popover>
                  </>
                )}
              </Box>
            </Box>
            
            <Stack direction="row" spacing={1}>
              <IconButton 
                size="small"
                href={`tel:${internationalizePhoneNumber(member.telephone)}`}
                sx={{ bgcolor: 'primary.50' }}
              >
                <LocalPhoneIcon fontSize="small" color="primary" />
              </IconButton>
              <IconButton 
                size="small"
                href={`sms:${internationalizePhoneNumber(member.telephone)}`}
                sx={{ bgcolor: 'primary.50' }}
              >
                <ChatBubbleOutlineIcon fontSize="small" color="primary" />
              </IconButton>
            </Stack>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}