// React
import { useState } from 'react';
// Helpers
import { showFriendlyDate } from '../../helpers/Utilities';
// MUI Components
import { Box, Button, Grid, Typography } from '@mui/material';
// MUI Icons
import PersonIcon from '@mui/icons-material/Person';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

type BookingSummaryProps = {
    classData: any;
    classSeries: any;
    selectedUsers: any[];
    selectedClasses: any[];
    user: any;
};

export default function BookingSummary({
    classData,
    classSeries,
    selectedUsers,
    selectedClasses,
    user
}: BookingSummaryProps) {

    // Check if class series exists
    const hasClassSeries = classSeries && classSeries.length > 0;
    
    // Early return conditions:
    // 1. No users selected
    // 2. OR there's a class series but no classes selected
    if (selectedUsers.length === 0 || (hasClassSeries && selectedClasses.length === 0)) return null;

    // Add state to manage expanded/collapsed view
    const [showAllClasses, setShowAllClasses] = useState(false);
    const classesToShowInitially = 4;


    // Calculate total classes based on whether class series exists
    const totalClasses = hasClassSeries 
    ? selectedClasses.length // For series, only count selected classes 
    : 1; // For single class, always count as 1

    const peopleCount = selectedUsers.length;

    // Map selectedUser IDs to actual names
    const selectedNames = selectedUsers.map(id => {
        if (id === user?.id) {
            return `${user.first_name} ${user.last_name} (You)`;
        } else {
            const familyMember = user?.family_members.find((member: { id: any; }) => member.id === id);
            return familyMember ? `${familyMember.first_name} ${familyMember.last_name}` : '';
        }
    }).filter(Boolean);

    // Limit displayed classes based on the show all state
    const displayedClasses = showAllClasses 
        ? selectedClasses 
        : selectedClasses.slice(0, classesToShowInitially);
    
    // Check if we need to show the "Show more" button
    const shouldShowMoreButton = selectedClasses.length > classesToShowInitially;

    return (
        <Box sx={{ mt: 3, mb: 3, p: 2, border: '1px solid #e0e0e0', borderRadius: 1, backgroundColor: '#f8f9fa' }}>
            <Typography variant="h6" gutterBottom>
                Booking Summary
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">People:</Typography>
                    <Typography variant="body1" fontWeight="medium">
                        {peopleCount} {peopleCount === 1 ? 'person' : 'people'}
                    </Typography>
                    <Box mt={1}>
                        {selectedNames.map((name, index) => (
                            <Typography key={index} variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                                <PersonIcon fontSize="small" sx={{ mr: 0.5 }} />
                                {name}
                            </Typography>
                        ))}
                    </Box>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">Classes:</Typography>
                    <Typography variant="body1" fontWeight="medium">
                        {totalClasses} {totalClasses === 1 ? 'class' : 'classes'}
                    </Typography>

                    {/* Only show the main class when not in series mode OR when no classes selected yet */}
                    {(!hasClassSeries || selectedClasses.length === 0) && (
                        <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                            <CheckCircleIcon fontSize="small" sx={{ mr: 0.5 }} />
                            {classData.name} ({showFriendlyDate(classData.date, user?.timezone)})
                        </Typography>
                    )}

                    {selectedClasses.length > 0 && (
                        <Box mt={1}>
                            {displayedClasses.map((selectedClass, index) => {
                                // Find the class details from classSeries
                                const classDetails = classSeries?.find(
                                    (cls: any) => cls.id.toString() === selectedClass.eventId
                                );

                                return classDetails ? (
                                    <Typography
                                        key={selectedClass.eventId}
                                        variant="body2"
                                        sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}
                                    >
                                        <CheckCircleIcon fontSize="small" sx={{ mr: 0.5 }} />
                                        {classDetails.name} ({showFriendlyDate(classDetails.date, user?.timezone)})
                                    </Typography>
                                ) : null;
                            })}

                                                        {/* Show more/less button */}
                                                        {shouldShowMoreButton && (
                                <Button
                                    size="small"
                                    color="primary"
                                    sx={{ mt: 1, textTransform: 'none' }}
                                    onClick={() => setShowAllClasses(!showAllClasses)}
                                    endIcon={showAllClasses ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                >
                                    {showAllClasses 
                                        ? "Show less" 
                                        : `Show all ${selectedClasses.length} classes`}
                                </Button>
                            )}
                        </Box>
                    )}
                </Grid>
            </Grid>

            <Box mt={2} p={1} bgcolor="primary.light" borderRadius={1}>
                <Typography variant="body2" align="center" color="primary.contrastText">
                Total bookings: {(hasClassSeries ? totalClasses : 1) * peopleCount}
                </Typography>
            </Box>
        </Box>
    );
};