import * as Yup from 'yup';
import { DateTime } from 'luxon';

export const createClassSchema = Yup.object().shape({
  newEventName: Yup.string()
    .required('Class Name Is Required'),
  newEventDescription: Yup.string()
    .required('Class Description Is Required'),
  newEventDuration: Yup.string()
    .required('Class Duration Is Required'),
  newEventLimit: Yup.number()
    .typeError('Class Limit must be a number')
    .required('Class Limit Is Required')
    .min(1, 'Class Limit must be greater than 0'),
  date: Yup.mixed()
    .test('is-date', 'Class Date Is Required', value => DateTime.isDateTime(value)),
  repeatEvent: Yup.string()
    .required('Class Repeat Is Required'),
  repeatEventAmount: Yup.number()
    .when('repeatEvent', {
      is: (v: string) => v === 'daily' || v === 'weekly',
      then(schema) {
        return schema.min(1, 'Repeat length must be greater than 0');
      },
    }),
});