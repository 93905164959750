// Types
import { ClassSummaryHeaderProps } from '../../types/types';
// Helpers
import { showFriendlyTime, showFriendlyDate } from '../../helpers/Utilities';
// MUI Components
import { Box, Grid, Typography } from '@mui/material';
// MUI Icons
import PersonIcon from '@mui/icons-material/Person';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ScheduleIcon from '@mui/icons-material/Schedule';
import TimelapseIcon from '@mui/icons-material/Timelapse';


export default function ClassSummaryHeader({ classData, user, selectedUsers, classSeries }: ClassSummaryHeaderProps) {
  if (!classData) return null;

  return (
    <>
      <Box textAlign={'center'} sx={{ marginTop: "15px" }}>
        <Typography variant={'body1'} fontWeight={'fontWeightMedium'}>{classData.club_name}</Typography>
        <Typography variant={'body1'} fontWeight={'fontWeightMedium'}>{classData.name}</Typography>
        {(!classSeries || classSeries.length === 0) && (
          <Box textAlign={'center'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <CalendarMonthOutlinedIcon sx={{ marginRight: '5px' }} />
            <Typography variant='subtitle2'>{showFriendlyDate(classData.date, user?.timezone)}</Typography>
          </Box>
        )}
      </Box>
      <Box sx={{ marginTop: '15px' }} justifyContent={'center'} textAlign={'center'} display={'flex'} alignItems={'space-between'}>
        {/* Class Time */}
        <ScheduleIcon sx={{ marginRight: '5px' }} />
        <Typography variant='subtitle2' sx={{ marginRight: '5px' }}>{showFriendlyTime(classData.date, user?.timezone)}</Typography>

        {/* Class Duration */}
        <TimelapseIcon sx={{ marginRight: '5px' }} />
        <Typography variant='subtitle2' sx={{ marginRight: '5px' }}>{classData.duration} Mins</Typography>

        {/* Spaces Left */}
        {(!classSeries || classSeries.length === 0) && (
          <>
          <PersonIcon sx={{ marginRight: '5px' }} /><Typography variant='subtitle2' sx={{ marginRight: '5px' }}>
            {classData.event_limit - classData.spaces_booked - selectedUsers.length}
            {' '}
            {classData.event_limit - classData.spaces_booked - selectedUsers.length === 1 ? 'space' : 'spaces'}
            {' '}
            left
          </Typography>
          </>
        )}
      </Box>
    </>
  );
};