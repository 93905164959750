// Types
import { Tournament } from "../types/types";

export const createNewTournament = async (data: any) => {
  const url = `${import.meta.env.VITE_API_ENDPOINT}/tournaments/api/new_tournament`;
  const response = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
};

export const getTournamentsByClubName = async (clubName: string) => {
  const url = `${import.meta.env.VITE_API_ENDPOINT}/tournaments/api/get_tournaments_by_club_name`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({ clubName }),
  });
  const json = await response.json();
  return json as Tournament[];
};

export const getTournamentById = async (tournamentId: string) => {
  const url = `${import.meta.env.VITE_API_ENDPOINT}/tournaments/api/get_tournament_by_id`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({ tournamentId }),
  });
  const json = await response.json();
  return json as Tournament;
};
