// Libs
import { useState } from 'react';
// Types
import { Stages } from '../types/types';
// Global Context
import { useAlert } from '../AlertContext';
// Hooks
import { useShowAlert } from "../hooks/useShowAlert";
// Components
import AlertMessage from '../components/Alerts/AlertMessage';
import ResetForm from '../components/PasswordReset/ResetForm';
import CodeForm from '../components/PasswordReset/CodeForm';
import NewPasswordForm from '../components/PasswordReset/NewPasswordForm';
// Material-UI
import { Container } from '@mui/material';


export default function ForgotPassword() {
  const { alert } = useAlert();
  const showAlert = useShowAlert();
  const [stage, setStage] = useState(Stages.REQUEST_LINK);

  const switchStage = (newStage: Stages) => {
    setStage(newStage);
  };
  
  return (
    <Container component="main" maxWidth="xs">
        
      {alert && (<AlertMessage />)}
        
      {stage === Stages.REQUEST_LINK && (
        <ResetForm showAlert={showAlert} switchStage={switchStage} />
      )}

      {stage === Stages.VERIFY && (
        <CodeForm showAlert={showAlert} switchStage={switchStage} />
      )}

      {stage === Stages.RESET && (
        <NewPasswordForm showAlert={showAlert} switchStage={switchStage} />
      )}
    </Container>
  );
}