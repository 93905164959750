import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from '../types/types';

const useRedirectIfNoUser = (user: User | null, isUserLoading: boolean) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Wait until loading is complete before checking the user
    if (!isUserLoading) {
      if (!user) {
        navigate('/'); // Redirects to login if no user
        return;
      }
    }
  }, [user, isUserLoading, navigate]);
};

export default useRedirectIfNoUser;