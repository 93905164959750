export type FamilyMember = {
  id: string;
  first_name: string;
  last_name: string;
  user_id: string;
  consent_given: boolean;
  member_profile_image_url: string;
}

export type User = {
  id: string;
  first_name: string;
  last_name: string;
  user_type: 'club' | 'member';
  club_name: string;
  email: string;
  telephone: string;
  timezone: string;
  family_members: FamilyMember[];
  fcm_token: string | null;
  user_role: string;
  booking_reminders: boolean;
  member_profile_image_url: string;
} | null;

export type SignInCredentials = {
  signInEmail: string;
  signInPassword: string;
}

export type Event = {
  id: number;
  name: string;
  description: string;
  event_limit: number;
  date: string;
  duration: string;
  user_id: number;
  club_name: string;
  spaces_booked: number;
  repeat_event_id: string;
  epoch_date: number;
};

export type Tournament = {
  id: number;
  name: string;
  description: string;
  club_name: string;
  user_id: number;
  address: string;
  google_maps_lat: number;
  google_maps_lng: number;
  link: string;
  date: string;
  epoch_date: number;
};

export type EventType = {
  id: number;
  name: string;
  description: string;
};

export type Booking = {
  id: number;
  club_name: string;
  date: string;
  event_id: number;
  event_name: string;
  user_id: string;
  family_member_id: string;
  user_first_name: string;
  user_last_name: string;
  family_member_details: FamilyMember;
}

export type AlertContent = {
  message: string;
  subMessage: string;
  status: 'success' | 'error';
}

export type CancelClassDialog = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  message: string;
}

export type DeleteClassTypeDialog = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  message: string;
}

export type FamilyMemberCancelItem = {
  id: string
}

export type CancelClassItem = {
  user_id: number;
  family_member_id?: string;
  family_member_details?: FamilyMemberCancelItem;
  event_id: number;
  club_name: string;
}

export type ClassSummaryHeaderProps = {
  classData: Event;
  user: User;
  selectedUsers: string[];
  classSeries: Event[] | [];
}

export type ClassSeriesSelectionProps = {
  classSeries: Event[];
  selectedClasses: {eventId: string, date: string}[];
  setSelectedClasses: React.Dispatch<React.SetStateAction<{eventId: string, date: string}[]>>;
  selectedUsers: string[];
  handleClassSelection: any;
  userTimeZone: string;
}

export type BookingAttendeesProps = {
  user: User;
  selectedUsers: string[];
  bookedMembers?: Booking[];
  handleMemberSelect: (memberId: string) => void;
  isMemberAlreadyBooked?: (bookedMembers: Booking[], member: User) => boolean;
  isFamilyMemberAlreadyBooked?: (bookedMembers: Booking[], familyMember: FamilyMember) => boolean;
}

export type ExistingBookingsProps = {
  bookings: Booking[];
  seriesBookings: Booking[];
  user: User;
}

export type AttendeesDialogProps = {
  open: boolean;
  onClose: () => void;
  attendees: any[];
  isAttendeesLoading?: boolean;
  dialogTitle?: string;
};

export enum Stages {
  REQUEST_LINK = 'REQUEST_LINK',
  VERIFY = 'VERIFY',
  RESET = 'RESET',
};
