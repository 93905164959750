import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AlertProvider } from './AlertContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { UserProvider } from './context/UserContext';
import { useUser } from './context/UserContext';
// import useCheckUpdateFCMToken from './hooks/useCheckUpdateFCMToken';
import useForegroundFirebaseMessaging from './hooks/useForegroundFirebaseMessage';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import ServiceWorkerUpdate from './components/Alerts/ServiceWorkerUpdate';

import Home from './pages/Home';
import UserLandingPage from './pages/UserLandingPage';
import ClubLandingPage from './pages/ClubLandingPage';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import Profile from './pages/Profile';
import ClubMembers from './pages/ClubMembers';
import ManageFamily from './pages/ManageFamily';
import SearchClasses from './pages/SearchClasses';
import BookClasses from './pages/BookClasses';
import BookClass from './pages/BookClass';
import Attendees from './pages/AttendeesPage';
import AttendanceSummary from './pages/AttendanceSummary';
import CreateClass from './pages/CreateClass';
import EditClassType from './pages/EditClassType';
import CreateClassType from './pages/CreateClassType';
import ClassPriceList from './pages/ClassPriceList';
import CreateNotification from './pages/CreateNotification';
import CreateTournament from './pages/CreateTournament';
import UpcomingTournaments from './pages/UpcomingTournaments';
import TournamentDetails from './pages/TournamentDetails';
import Contact from './pages/Contact';
import FooterNav from './components/Nav/FooterNav';
import NavigationBar from './components/Nav/NavigationBar';
import './App.css';

const queryClient = new QueryClient();

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
});

function App() {
  const { user } = useUser();
  const userId = user?.id;

  // useCheckUpdateFCMToken(userId);
  useForegroundFirebaseMessaging();

  return (
    <UserProvider>
    <QueryClientProvider client={queryClient}>
      <ServiceWorkerUpdate />
      <AlertProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <div>
              <NavigationBar />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/userlanding" element={<UserLandingPage />} />
                <Route path="/clublanding" element={<ClubLandingPage />} />
                <Route path="/signin/:clubName" element={<SignIn />} />
                <Route path="/signup/:clubName" element={<SignUp />} />
                <Route path="/forgotpassword" element={<ForgotPassword />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/members" element={<ClubMembers />} />
                <Route path="/attendance" element={<AttendanceSummary />} />
                <Route path="/managefamily" element={<ManageFamily />} />
                <Route path="/createclass" element={<CreateClass />} />
                <Route path="/createclasstype" element={<CreateClassType />} />
                <Route path="/editclasstype" element={<EditClassType />} />
                <Route path="/searchclasses" element={<SearchClasses />} />
                <Route path="/bookclasses" element={<BookClasses />} />
                <Route path="/pricelist" element={<ClassPriceList />} />
                <Route path="/createtournament" element={<CreateTournament />} />
                <Route path="/createnotification" element={<CreateNotification />} />
                <Route path="/upcomingevents" element={<UpcomingTournaments />} />
                <Route path="/event/:tournamentId" element={<TournamentDetails />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/bookclass/:eventId" element={<BookClass />} />
                <Route path="/attendees/:eventId" element={<Attendees />} />
              </Routes>
              <FooterNav />
            </div>
          </BrowserRouter>
        </ThemeProvider>
      </AlertProvider>
    </QueryClientProvider>
    </UserProvider>
  );
}

export default App;