export const sendTestNotification = async () => {
    const url = `${import.meta.env.VITE_API_ENDPOINT}/notifications/api/send_test_notification`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const json = await response.json();
    return json;
  };