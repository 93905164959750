// Libs
import { useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
// Global Context
import { useAlert } from '../AlertContext';
import { useUser } from "../context/UserContext";
// Hooks
import { useShowAlert } from "../hooks/useShowAlert";
import useClubRedirect from '../hooks/useClubRedirect';
// Api Requests
import { sendCancelEventRequest } from '../api/Events';
import { retrieveAttendees, updateAttendance } from "../api/Bookings";
// Helpers
import { showFriendlyDate, showFriendlyTime, internationalizePhoneNumber } from "../helpers/Utilities";
// Types
import { CancelClassItem } from "../types/types";
// Components
import CancelClassConfirmation from '../components/Dialog/CancelClassConfirmation';
import Loading from "../components/Alerts/Loading";
import AlertMessage from '../components/Alerts/AlertMessage';
// Material-UI
import { 
  Avatar, Box, Button, Card, CardContent, Checkbox, 
  Container, IconButton, Typography, 
  LinearProgress, Stack, Grid, Paper,
  MenuItem, Menu, ListItemIcon, ListItemText
} from "@mui/material";
// Material-UI Icons
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PersonIcon from '@mui/icons-material/Person';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function Attendees() {
  const navigate = useNavigate();
  const { eventId } = useParams();
  const { user, isUserLoading } = useUser();
  const { alert } = useAlert();
  const showAlert = useShowAlert();
  const [isDialogOpen, setDialogOpen] = useState(false);
  // State to keep track of the class item to be cancelled
  const [classToCancel, setClassToCancel] = useState<CancelClassItem | null>(null);
  const [disableCancel, setDisableCancel] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  
  // Mark all as attended
  const handleMarkAllAttended = () => {
    if (Array.isArray(bookingData)) {
      bookingData.forEach(booking => {
        if (!booking.did_attend) {
          handleUpdateAttendanceRequest(
            booking.id,
            booking.user_id,
            true,
            booking.family_member_id
          );
        }
      });
    }
    handleMenuClose();
  };
  
  // Redirect user if not a club user
  useClubRedirect(user, isUserLoading);

  // Handler to send cancel class request
  const handeCancelRequest = async (userId: string, eventId: string) => {
    try {
      const response = await sendCancelEventRequest(userId, eventId);
      if (response.success) {
        showAlert(response.success.message, response.success.subMessage, 'success');
        queryClient.invalidateQueries({ queryKey: ['clubBookingData', eventId] });
        setDisableCancel(true);
      }

      if (response.error) {
        showAlert(response.error.message, response.error.subMessage, 'error');
      }
    } catch (error) {
      showAlert('Error', 'Please try again.', 'error');
    }
  };

  // Handler to open the dialog and set the class to cancel
  const handleCancelClick = (classItem: any) => {
    setClassToCancel(classItem);
    setDialogOpen(true);
  };

  // Handler for when user confirms cancellation
  const handleConfirmCancellation = () => {
    handeCancelRequest(user?.id ?? '', eventId ?? '');
    // Close the dialog
    setDialogOpen(false);
    // Reset the class to cancel
    setClassToCancel(null);
  };

  // Handler for closing the dialog without action
  const handleCloseDialog = () => {
    setDialogOpen(false);
    setClassToCancel(null);
  };

  const queryClient = useQueryClient();
  const { data: bookingData, error, isLoading } = useQuery({
    queryKey: ['clubBookingData', eventId],
    queryFn: () => retrieveAttendees(eventId || ''),
    enabled: !!eventId
  });

  // Handler to update attendance
  const handleUpdateAttendanceRequest = async (bookingId: string, userId: string, attend: boolean, familyMemberId: string) => {
    try {
      const response = await updateAttendance(bookingId, userId, attend, familyMemberId);
      if (response.success) {
        showAlert(response.success.message, response.success.subMessage, 'success');
        queryClient.invalidateQueries({ queryKey: ['clubBookingData', eventId] });
      }

      if (response.error) {
        showAlert(response.error.message, response.error.subMessage, 'error');
      }
    } catch (error) {
      showAlert('Error', 'Please try again.', 'error');
    }
  };

  const attendancePercentage = Array.isArray(bookingData) && bookingData.length > 0 ? 
    (bookingData.filter(b => b.did_attend).length / bookingData.length) * 100 : 0;

  // If still loading, show loading state
  if (isUserLoading || isLoading) {
    return <Loading text="" />
  }

  if (error) return <p>An error has occurred: {error.message}</p>;

  return (
    <Container component="main" maxWidth="sm" sx={{ pb: 10, pt: 10 }}>
      {alert && <AlertMessage />}
      
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <IconButton 
          sx={{ mr: 1 }}
          onClick={() => navigate('/clublanding')}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Class Attendance
        </Typography>
        <IconButton
          onClick={handleMenuClick}
          aria-controls={menuOpen ? "actions-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen ? "true" : undefined}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="actions-menu"
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleMenuClose}
          MenuListProps={{
            'aria-labelledby': 'actions-button',
          }}
        >
          <MenuItem onClick={handleMarkAllAttended}>
            <ListItemIcon>
              <CheckBoxIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Mark all as attended</ListItemText>
          </MenuItem>
          <MenuItem disabled={disableCancel} onClick={() => {
            handleCancelClick(bookingData[0]);
            handleMenuClose();
          }}>
            <ListItemIcon>
              <CancelScheduleSendIcon fontSize="small" color={disableCancel ? "disabled" : "error"} />
            </ListItemIcon>
            <ListItemText primary="Cancel Class" primaryTypographyProps={{ color: disableCancel ? "text.disabled" : "error" }} />
          </MenuItem>
        </Menu>
      </Box>
      
      {bookingData?.length > 0 && (
        <Card elevation={1} sx={{ mb: 4, borderRadius: 2, overflow: 'hidden' }}>
          <Box sx={{ p: 3, textAlign: 'center' }}>
            <Typography variant="h6" sx={{ mb: 0.5 }}>{bookingData[0]?.event_name}</Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
              {showFriendlyDate(bookingData[0]?.date, user?.timezone)} • {showFriendlyTime(bookingData[0]?.date, user?.timezone)}
            </Typography>
            
            <Grid container spacing={3} sx={{ mb: 2 }}>
              <Grid item xs={6}>
                <Paper elevation={0} sx={{ p: 2, bgcolor: 'background.default', textAlign: 'center' }}>
                  <Typography variant="h4" color="primary" sx={{ mb: 1 }}>
                    {bookingData.length}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Total Booked
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper elevation={0} sx={{ p: 2, bgcolor: 'background.default', textAlign: 'center' }}>
                  <Typography variant="h4" sx={{ color: 'success.main', mb: 1 }}>
                    {bookingData.filter((b: { did_attend: any; }) => b.did_attend).length}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Attended
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            
            <Box sx={{ mb: 0.5, display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="body2" color="text.secondary">Attendance Rate</Typography>
              <Typography variant="body2" fontWeight="medium">
                {attendancePercentage.toFixed(0)}%
              </Typography>
            </Box>
            <LinearProgress 
              variant="determinate" 
              value={attendancePercentage} 
              sx={{ 
                height: 8, 
                borderRadius: 4,
                bgcolor: 'grey.200',
                '& .MuiLinearProgress-bar': {
                  bgcolor: attendancePercentage >= 75 ? 'success.main' : 
                           attendancePercentage >= 50 ? 'warning.main' : 'error.main'
                }
              }} 
            />
          </Box>
        </Card>
      )}
      
      {Array.isArray(bookingData) && bookingData.length === 0 ? (
        <Box sx={{ textAlign: 'center', py: 6 }}>
          <Typography variant="h6" gutterBottom>No Attendees Booked</Typography>
          <Typography color="text.secondary" sx={{ mb: 4, maxWidth: 300, mx: 'auto' }}>
            There are currently no bookings for this class. Check back later or view another class.
          </Typography>
          <Button 
            variant="contained" 
            onClick={() => navigate('/searchclasses')}
            sx={{ borderRadius: 6, px: 3 }}
          >
            Browse Classes
          </Button>
        </Box>
      ) : (
        <>
          <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500 }}>
            Attendee List
          </Typography>
          
          {Array.isArray(bookingData) && bookingData.map((booking) => (
            <Card 
              key={booking.id} 
              sx={{ 
                mb: 2, 
                borderRadius: 2,
                transition: 'all 0.2s',
                borderLeft: booking.did_attend ? '4px solid' : '1px solid',
                borderColor: booking.did_attend ? 'success.main' : 'divider',
              }}
            >
              <CardContent sx={{ p: 2, '&:last-child': { pb: 2 } }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    checked={booking.did_attend}
                    onChange={(event) => {
                      handleUpdateAttendanceRequest(
                        booking.id,
                        booking.user_id,
                        event.target.checked,
                        booking.family_member_id
                      );
                    }}
                    icon={<CheckBoxOutlineBlankIcon />}
                    checkedIcon={<CheckBoxIcon color="success" />}
                  />
                  
                  <Avatar 
                    src={booking.family_member_details ? 
                      booking.family_member_details.member_profile_image_url : 
                      booking.user_profile_image_url
                    }
                    sx={{ width: 40, height: 40 }}
                  >
                    {!booking.user_profile_image_url && <PersonIcon />}
                  </Avatar>
                  
                  <Box sx={{ ml: 2, flexGrow: 1 }}>
                    <Typography variant="body1" fontWeight="500">
                      {booking.family_member_details ? 
                        `${booking.family_member_details.first_name} ${booking.family_member_details.last_name}` : 
                        `${booking.user_first_name} ${booking.user_last_name}`
                      }
                    </Typography>
                  </Box>
                  
                  <Stack direction="row" spacing={1}>
                    <IconButton 
                      size="small"
                      href={`tel:${internationalizePhoneNumber(booking.user_telephone)}`}
                      sx={{ bgcolor: 'primary.50' }}
                    >
                      <LocalPhoneIcon fontSize="small" color="primary" />
                    </IconButton>
                    <IconButton 
                      size="small"
                      href={`sms:${internationalizePhoneNumber(booking.user_telephone)}`}
                      sx={{ bgcolor: 'primary.50' }}
                    >
                      <ChatBubbleOutlineIcon fontSize="small" color="primary" />
                    </IconButton>
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          ))}
        </>
      )}
      
      <CancelClassConfirmation
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmCancellation}
        message="Are you sure you want to cancel this class?"
      />
    </Container>
  );
}