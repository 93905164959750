
import { showFriendlyDate } from '../../helpers/Utilities';
import { Card, CardContent, Typography, Box, Button } from '@mui/material';

import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

export default function TournamentList({ tournament, user, navigate }: { tournament: any, user: any, navigate: any }) {
  return (
    <Card key={tournament.id} sx={{mb: 2, '&:hover': { cursor: 'pointer' }}} onClick={() => navigate(`/event/${tournament.id}`)}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" sx={{marginBottom: '5px'}}>
          {/* Tournament Date & More Info Button */}
          <Typography sx={{fontWeight: '500', paddingLeft: '4px'}} key={tournament.id}>{tournament.name}</Typography>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Button variant="contained" color="primary" size="small" onClick={() => navigate(`/event/${tournament.id}`)}>More Info</Button>
            </Box>
        </Box>
        {/* Tournament Date */}
        <Box display="flex" sx={{marginBottom: '5px'}}>
          <CalendarMonthOutlinedIcon sx={{ fontSize: 'med', marginRight: '5px' }} />
          <Typography>{showFriendlyDate(tournament.date, user?.timezone)}</Typography>
        </Box>
      </CardContent>
    </Card>
    )
}