// Libs
import { useEffect, useState } from 'react';
// Hooks
import { useShowAlert } from "../hooks/useShowAlert";
import useClubRedirect from '../hooks/useClubRedirect';
// Api Requests
import { editEventType, createEventType } from '../api/EventTypes';
// Global Context
import { useAlert } from '../AlertContext';
import { useUser } from '../context/UserContext';
// Components
import Loading from "../components/Alerts/Loading";
import AlertMessage from '../components/Alerts/AlertMessage';
import ClassTypeForm from '../components/Events/Forms/ClassTypeForm';
// Material-UI
import { Box, Container, Typography } from '@mui/material';

export default function CreateClassType() {
  const [existingEvent, setExistingEvent] = useState(() => {
    const storedEvent = localStorage.getItem('existingEvent');
    return storedEvent ? JSON.parse(storedEvent) : null;
  });

  // Clear LocalStorage existingEvent
  useEffect(() => {
    localStorage.removeItem('existingEvent');
  }, []); // Empty dependency array means this effect runs only on mount and unmount
    
  const { alert } = useAlert();
  const showAlert = useShowAlert();
  const { user, isUserLoading } = useUser();

  // Redirect user if not a club user
  useClubRedirect(user, isUserLoading);
 
  const handleSubmit = async (data: any, resetForm: () => void) => {
    try {
      let response;
      if (existingEvent)  {
        response = await editEventType(data);
      } else {
        response = await createEventType(data);
      }

      if (response.success) {
        showAlert(response.success.message, response.success.subMessage, 'success');
        localStorage.removeItem('existingEvent');
        resetForm();
      }

      if (response.error) {
        showAlert(response.error.message, response.error.subMessage, 'error');
      }
    } catch (error) {
      showAlert('Error', 'Please try again.', 'error');
    }
  };

  if (isUserLoading) {
    return <Loading text="" />
  }
  
    return (
      <Container component="main" maxWidth="sm" sx={{ pt: '64px' }}>
      {alert && (<AlertMessage />)}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
      <Typography sx={{ paddingTop: 2 }}>
        {existingEvent ? 'Edit Class Type' : 'Create Class Type'}
      </Typography>
      <ClassTypeForm existingEvent={existingEvent} user={user} handleSubmit={handleSubmit} />
    </Box>
  </Container>   
    );
  }