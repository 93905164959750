import { useQuery } from '@tanstack/react-query'
// Global Context
import { useAlert } from '../AlertContext';
import { useUser } from '../context/UserContext';
// Hooks
import { useShowAlert } from "../hooks/useShowAlert";
import useClubRedirect from '../hooks/useClubRedirect';
// Api Requests
import { getEventTypeByUserId } from '../api/EventTypes';
// Services
import { createNewEvent } from '../api/Events';
// Helpers
import { scrollToTop } from '../helpers/Utilities';
// Components
import Loading from "../components/Alerts/Loading";
import AlertMessage from '../components/Alerts/AlertMessage';
import CreateClassForm from '../components/Events/Forms/CreateClassForm';
// Material-UI
import { Box, Container, Typography } from '@mui/material';

export default function CreateClass() {
  const { user, isUserLoading } = useUser();
  const { alert } = useAlert();
  const showAlert = useShowAlert();

  // Redirect user if not a club user
  useClubRedirect(user, isUserLoading);

  // useQuery hook from react-query
  const { data: eventTypes, isLoading } = useQuery({
    queryKey: ['clubEventTypes', user?.id],
    queryFn: () => getEventTypeByUserId(user?.id ?? ''),
    enabled: !!user?.id
  });

  const handleCreateNewEvent = async (data: any, resetForm: () => void) => {
    try {
      const response = await createNewEvent(data);
      if (response.success) {
        scrollToTop();
        showAlert(response.success.message, response.success.subMessage, 'success');
        resetForm();
      }

      if (response.error) {
        showAlert(response.error.message, response.error.subMessage, 'error');
      }
    } catch (error) {
      showAlert('Error', 'Please try again.', 'error');
    }
  };

  if (isUserLoading || isLoading || !eventTypes) {
    return <Loading text="" />
  }

  return (
    <Container component="main" maxWidth="sm" sx={{ pt: '64px' }}>
      {alert && (
        <AlertMessage />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: 800,
          overflow: "hidden",
          overflowY: "scroll",
        }}
      >
        <Typography sx={{ paddingTop: 2 }}>Create New Class</Typography>
        <CreateClassForm user={user} classTypeData={eventTypes} handleCreateNewEvent={handleCreateNewEvent} />
      </Box>
    </Container>
  );
}