// Libs
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query'
// Global Context
import { useUser } from "../context/UserContext";
// Hooks
import useRedirectIfNoUser from '../hooks/useRedirectIfNoUser';
// Api Requests
import { getTournamentById } from '../api/Tournaments';
// Helpers
import { showFriendlyDate, showFriendlyTime } from '../helpers/Utilities';
// Components
import Loading from "../components/Alerts/Loading";
import BasicMap from "../components/Maps/BasicMap";
import ErrorPage from './ErrorPage';
// Material-UI
import { Box, Container, Link, Paper, Typography } from '@mui/material';
// Material-UI Icons
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ScheduleIcon from '@mui/icons-material/Schedule';

export default function TournamentDetails() {
  const { tournamentId } = useParams();
  const { user, isUserLoading } = useUser();

  // Restrict access to page if not user
  useRedirectIfNoUser(user, isUserLoading);
  
  const { data: tournamentData, error, isLoading } = useQuery({
    queryKey: ['tournamentsData', tournamentId,],
    queryFn: () => getTournamentById(tournamentId ?? ''),
    enabled: !!tournamentId,
  });

  // If still loading, show loading state
  if (isUserLoading || isLoading) {
    return <Loading text="" />
  }

  if (error) {
    <ErrorPage
      title="Oops! Something went wrong"
      message="We're sorry, but we encountered an unexpected error while processing your request"
    />
  }

  return(
    <Container component="main" maxWidth="xs" sx={{ pt: '64px',  height: '110vh', overflowY: 'auto' }}>
      {tournamentData ? (
        <>
        <Box textAlign={'center'} sx={{ marginTop: 3,  }}>
          <Typography fontWeight={'fontWeightMedium'}>{tournamentData.name}</Typography>
          <Box textAlign={'center'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            { /* Date */}
            <CalendarMonthOutlinedIcon sx={{ marginRight: '5px' }} />
            <Typography variant='subtitle2' sx={{ marginRight: 1 }}>{showFriendlyDate(tournamentData.date, user?.timezone)}</Typography>
            {/* Time */}
            <ScheduleIcon sx={{ marginRight: '5px' }}/>
            <Typography variant='subtitle2' sx={{ marginRight: '5px' }}>{showFriendlyTime(tournamentData.date, user?.timezone)}</Typography>
          </Box>
        </Box>
        
        <Box sx={{ marginTop: '15px' }}>
          <Typography fontWeight={'fontWeightMedium'}>About</Typography>
          <Typography sx={{marginTop: '10px'}}>{tournamentData.description}</Typography>
        </Box>

        {tournamentData.link && (
        <Box sx={{ marginTop: '15px' }}>
          <Typography fontWeight={'fontWeightMedium'}>Link</Typography>
          <Link href={tournamentData.link} target="_blank" rel="noopener" sx={{marginTop: '10px', display: 'block'}}>
            {tournamentData.link}
          </Link>
        </Box>
        )}

        <Box sx={{ marginTop: '15px' }}>
          <Typography fontWeight={'fontWeightMedium'}>Location</Typography>
          <Typography sx={{marginTop: '10px'}}>{tournamentData.address}</Typography>
        </Box>

        <Box sx={{ marginTop: '15px' }}>
          <Paper elevation={3}>
            <BasicMap lat={tournamentData.google_maps_lat} lng={tournamentData.google_maps_lng} />
          </Paper>
        </Box>
        </>
      ) : (
       <p>No tournament data</p>
      )}
    </Container>
  )
};