export const userSignUp = async (data: any) => {
  const url = `${import.meta.env.VITE_API_ENDPOINT}/auth/api/sign_up`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {Accept: 'application/json', 'Content-Type': 'application/json',},
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
};

export const updateUserProfile = async (data: any) => {
  const url = `${import.meta.env.VITE_API_ENDPOINT}/users/api/update_user_profile`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {Accept: 'application/json', 'Content-Type': 'application/json',},
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
}

export const retrieveClubMembers = async (clubName: string) => {
  const url = `${import.meta.env.VITE_API_ENDPOINT}/users/api/club_members`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {Accept: 'application/json', 'Content-Type': 'application/json',},
    body: JSON.stringify({ clubName }),
  });
  const json = await response.json();
  return json.users;
}

export const uploadImageToS3 = async (userId: string, file: File) => {
  const url = `${import.meta.env.VITE_API_ENDPOINT}/users/api/update_user_profile_image`;
  const formData = new FormData();
  formData.append('userId', userId);
  formData.append('file', file);

  const response = await fetch(url, {
    method: 'POST',
    headers: {Accept: 'application/json'},
    body: formData,
  });
  const json = await response.json();
  return json;
}

export const updateFCMToken = async (userId: string, fcmToken: string) => {
  const url = `${import.meta.env.VITE_API_ENDPOINT}/users/api/update_user_fcm_token`;
  const formData = new FormData();
  formData.append('userId', userId);
  formData.append('fcmToken', fcmToken);
  const response = await fetch(url, {
    method: 'POST',
    headers: { Accept: 'application/json' },
    body: formData,
  });
  const json = await response.json();
  return json;
}

export const getUserFCMToken = async (userId: string) => {
  const url = `${import.meta.env.VITE_API_ENDPOINT}/users/api/get_user_fcm_token`;
  const formData = new FormData();
  formData.append('userId', userId);
  const response = await fetch(url, {
    method: 'POST',
    headers: { Accept: 'application/json' },
    body: formData,
  });
  const json = await response.json();
  return json;
}

export const updateBookingReminders = async (userId: string, bookingRemindersEnabled: string) => {
  const url = `${import.meta.env.VITE_API_ENDPOINT}/users/api/update_user_booking_reminders`;
  const formData = new FormData();
  formData.append('userId', userId);
  formData.append('bookingRemindersEnabled', bookingRemindersEnabled);
  const response = await fetch(url, {
    method: 'POST',
    headers: { Accept: 'application/json' },
    body: formData,
  });
  const json = await response.json();
  return json;
}