// Libs
import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// Global Context
import { useUser } from '../../context/UserContext';
// Helpers
import { getClubNameToLowerCase } from '../../helpers/ClubNames';
// Material-UI
import { AppBar, Box, Collapse, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Toolbar, Typography } from '@mui/material';
// Material-UI Icons
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PeopleIcon from '@mui/icons-material/People';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import InfoIcon from '@mui/icons-material/Info';
import BellIcon from '@mui/icons-material/Notifications';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import RefreshIcon from '@mui/icons-material/Refresh';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

export default function NavigationBar() {
  const { user, signOut } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const [expandedSections, setExpandedSections] = useState<Record<string, boolean>>({});
  const navigate = useNavigate();

  if (!user) return null;

  const toggleSection = (section: string) => (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent drawer from closing
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setIsOpen(open);
  };

  const handlePageReload = () => {
    window.location.reload();
  };

  const memberList = () => (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <List>
        {[
          { text: 'Home', path: '/userlanding ' },
          { text: 'Search Classes', path: '/bookclasses' },
          { text: 'Upcoming Events', path: '/upcomingevents' },
          { text: 'Manage Family', path: '/managefamily' },
          { text: 'Profile', path: '/profile' },
          { text: 'Contact', path: '/contact' },
          { text: 'Prices', path: '/pricelist' },
          { text: 'Check Updates', action: handlePageReload }].map(item => (
            <ListItem key={item.text} component={item.path ? RouterLink : 'div'} to={item.path} button disablePadding onClick={item.action}>
              <ListItemButton>
                <ListItemIcon>
                  {{
                    'Home': <HomeIcon />,
                    'Search Classes': <SearchIcon />,
                    'Upcoming Events': <EmojiEventsIcon />,
                    'Manage Family': <PeopleIcon />,
                    'Profile': <AccountBoxIcon />,
                    'Contact': <InfoIcon />,
                    'Prices': <CurrencyPoundIcon />,
                    'Check Updates': <RefreshIcon />
                  }[item.text]}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
      <Divider />
      {signOutLink()}
    </Box>
  );

  const clubNavigation = [
    { standalone: true, text: 'Home', path: '/clublanding', icon: <HomeIcon /> },
    { standalone: true, text: 'Check Updates', action: handlePageReload, icon: <RefreshIcon /> },
    {
      section: 'Class Types',
      items: [
        { text: 'Create Class Type', path: '/createclasstype', icon: <AddIcon /> },
        { text: 'Edit Class Type', path: '/editclasstype', icon: <ModeEditIcon /> },
      ]
    },
    {
      section: 'Classes',
      items: [
        { text: 'Search Classes', path: '/searchclasses', icon: <SearchIcon /> },
        { text: 'Create Class', path: '/createclass', icon: <AddIcon /> },
      ]
    },
    {
      section: 'Events',
      items: [
        { text: 'Create Event', path: '/createtournament', icon: <EmojiEventsIcon />, action: undefined as (() => void) | undefined },
        { text: 'Upcoming Events', path: '/upcomingevents', icon: <EmojiEventsIcon />, action: undefined as (() => void) | undefined },
      ]
    },
    {
      section: 'Members',
      items: [
        { text: 'All Members', path: '/members', icon: <PeopleIcon /> },
        { text: 'Attendance', path: '/attendance', icon: <PeopleIcon /> },
      ]
    },
  ];

  const clubList = () => {    
    return (
      <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
        <List>
          {clubNavigation.map((item, index) => (
            // Check if this is a standalone item or a section
            item.standalone ? (
              // Render standalone item
              <ListItem 
                key={`item-${item.text}`} 
                component={item.path ? RouterLink : 'div'} 
                to={item.path} 
                disablePadding 
                onClick={item.action}
                sx={{ 
                  color: 'text.primary',
                  '& .MuiListItemText-root': { color: 'inherit' },
                  '& a': { color: 'inherit', textDecoration: 'none' },
                  transition: 'none'
                }}
              >
                <ListItemButton>
                  <ListItemIcon sx={{ minWidth: '40px' }}>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ) : (
              // Render collapsible section
              <React.Fragment key={`section-${item.section}`}>
                <Divider sx={{ my: 1 }} />
                {/* Make the header clickable to expand/collapse */}
                <ListItemButton 
                  disableRipple
                  onClick={toggleSection(item.section || '')}
                  sx={{ 
                    py: 0,
                    minHeight: 32,
                  }}
                >
                  <ListItemText 
                    primary={item.section}
                  />
                  {/* Add an icon to indicate expanded state */}
                  {expandedSections[item.section || ''] ? 
                    <ExpandLess /> : 
                    <ExpandMore />
                  }
                </ListItemButton>
                
                {/* Collapsible section content */}
                <Collapse in={expandedSections[item.section || '']} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.items?.map(subItem => (
                      <ListItem 
                        key={subItem.text} 
                        component={subItem.path ? RouterLink : 'div'} 
                        to={subItem.path} 
                        disablePadding
                        sx={{
                          color: 'text.primary',
                          '& .MuiListItemText-root': { color: 'inherit' },
                          '& a': { color: 'inherit', textDecoration: 'none' },
                          transition: 'none'
                        }}
                        // onClick={subItem.action}
                      >
                        <ListItemButton
                          disableRipple
                        >
                          <ListItemIcon sx={{ minWidth: '40px' }}>{subItem.icon}</ListItemIcon>
                          <ListItemText 
                            primary={subItem.text} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </React.Fragment>
            )
          ))}
        </List>
        <Divider />
        {signOutLink()}
      </Box>
    );
  };

  const signOutLink = () => (
    <ListItem disablePadding>
      <ListItemButton>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        {user
          ? <ListItemText primary="Sign Out" onClick={handleSignOut} />
          : (
            <RouterLink to='/signin' style={{ textDecoration: 'none' }}>
              <ListItemText primary="Sign In" />
            </RouterLink>
          )
        }
      </ListItemButton>
    </ListItem>

  );

  const handleSignOut = () => {
    const clubName = getClubNameToLowerCase(user?.club_name);
    const url = `${import.meta.env.VITE_API_ENDPOINT}/auth/api/logout`;
    fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          navigate(`/signin/${clubName}?loggedOut=true`); // Redirect the user to the sign-in page or wherever you want
          signOut(); // Clear user data in cache
        } else {
          console.error(data.message);
        }
      })
      .catch(error => {
        console.error("Failed to sign out:", error);
      });
  };

  return (
    <div>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          {/* Add any other elements or text for your navbar here. */}
        </Toolbar>
      </AppBar>

      <Drawer anchor="left" open={isOpen} onClose={toggleDrawer(false)}>
        {user?.user_type === 'club' ? clubList() : memberList()}
      </Drawer>
    </div>
  );
}