// Libs
import { Fragment } from "react"
// Helpers
import { showFriendlyDate, showFriendlyClassStartEnd } from '../../helpers/Utilities';
// Material-UI
import { Box, Card, CardActionArea, CardContent, Chip, Divider, Typography } from "@mui/material"
// Material-UI Icons
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';

export default function AllClubClassesList({ date, classItems, user, navigate }: { date: string, classItems: any[], user: any, navigate: any}) {
  return (
    <Fragment key={date}>
      <Typography variant="h6" sx={{ mb: 1 }}>{date}</Typography> {/* This is the subheader */}
      
      {classItems.map((classItem: any) => {
        return (
          <Card
            key={classItem.id}
            sx={{
              transition: 'transform 0.2s, box-shadow 0.2s',
              borderRadius: theme => theme.spacing(1),
              mb: 2
            }}
          >
            <CardActionArea onClick={() => navigate(`/attendees/${classItem.id}`)}>
              <CardContent>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                  <Typography variant="body1" fontWeight="600" fontSize={18}>{classItem.name}</Typography>
                  <Chip 
                    label={`${classItem.spaces_booked}/${classItem.event_limit}`}
                    color={classItem.spaces_booked === classItem.event_limit ? 'success' : 'primary'}
                    size="medium"
                    variant='outlined'
                    icon={<PermIdentityOutlinedIcon sx={{ fontSize: 16 }} />}
                    sx={{ fontWeight: 500, height: '24px' }}
                  />
                </Box>
                
                <Divider sx={{ my: 1.5 }} />
                
                <Box mt={2}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      mb: 1,
                      color: 'text.primary'
                    }}
                  >
                    <CalendarMonthOutlinedIcon sx={{ fontSize: 20, mr: 1.5 }} />
                    <Typography variant="body2">
                      {showFriendlyDate(classItem.date, user?.timezone)}
                    </Typography>
                  </Box>
                  
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: 'text.primary'
                    }}
                  >
                    <AccessTimeOutlinedIcon sx={{ fontSize: 20, mr: 1.5 }} />
                    <Typography variant="body2">
                      {showFriendlyClassStartEnd(classItem.date, classItem.duration, user?.timezone)}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </CardActionArea>
          </Card>
        );
      })}
    </Fragment>
  )
}