// Libs
import { useState } from 'react';
import { Helmet } from 'react-helmet';
// Global Context
import { useAlert } from '../AlertContext';
import { useUser } from '../context/UserContext';
// Hooks
import { useShowAlert } from "../hooks/useShowAlert";
import useRedirectIfNoUser from '../hooks/useRedirectIfNoUser';
// Api Requests
import { updateUserProfile, uploadImageToS3 } from '../api/Users';
// Components
import Loading from "../components/Alerts/Loading";
import AlertMessage from '../components/Alerts/AlertMessage';
import ProfileForm from '../components/Members/Forms/ProfileForm';
// Material-UI
import { Box, Container } from '@mui/material';

export default function Profile() {
  const { alert } = useAlert();
  const showAlert = useShowAlert();
  const { user, isUserLoading, updateUserData } = useUser();

  // Edit Profile State
  const [isEditMode, setIsEditMode] = useState(false);

  // Redirect user if not logged in
  useRedirectIfNoUser(user, isUserLoading);

  const handleUpdateProfile = async (data: any) => {
    try {
      const response = await updateUserProfile(data);
      if (response.success) {
        showAlert(response.success.message, response.success.subMessage, 'success');
        // Store the updated user data
        const updatedUserData = response.user;
        // Update the user context with the new data
        updateUserData(updatedUserData);
      }

      if (response.error) {
        showAlert(response.error.message, response.error.subMessage, 'error');
      }
    } catch (error) {
      showAlert('Error', 'Please try again.', 'error');
    }
  };

  const handleUpdateProfileImage = async (userId: string, file: File) => { 
    try {
      const response = await uploadImageToS3(userId, file);
      if (response.success) {
        let userData: { member_profile_image_url: string } | null = JSON.parse(localStorage.getItem('userData') || 'null');
        if (userData) {
          // Modify the property, for example 'name'
          userData.member_profile_image_url = response.image_url;

          // Convert the object back to a string and save it
          localStorage.setItem('userData', JSON.stringify(userData));
        }
        showAlert(response.success.message, response.success.subMessage, 'success');
      }

      if (response.error) {
        showAlert(response.error.message, response.error.subMessage, 'error');
      }
    } catch (error) {
      showAlert('Error', 'Please try again.', 'error');
    }    
  }

  if (isUserLoading) {
    return <Loading text="" />
  }

  return (
    <>
      <Helmet>
        <title>{`${user?.club_name || ''} | Profile`}</title>
      </Helmet>
      <Container component="main" maxWidth="xs" sx={{ pt: '64px' }}>
        {alert && (<AlertMessage />)}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: 675,
            alignItems: 'center',
            overflow: "hidden",
            overflowY: "scroll",
          }}
        >
          <ProfileForm
            user={user}
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            handleUpdateProfile={handleUpdateProfile}
            handleUpdateProfileImage={handleUpdateProfileImage}
            showAlert={showAlert}
            updateUserData={updateUserData}
          />
        </Box>
      </Container>
    </>
  );
}