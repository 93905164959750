import { useState } from 'react';
// Libs
import { useFormik } from 'formik';
import { DateTime } from 'luxon';
// Validations
import { createClassSchema } from '../../../validations/CreateClassSchema';
// Types
import { EventType } from '../../../types/types';
// Material-UI
import { Box, Button, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
// Material-UI icons
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

interface ClassFormProps {
  user: any;
  classTypeData: EventType[];
  handleCreateNewEvent: (values: any, resetForm: () => void) => void;
}

export default function CreateClassForm({ user, classTypeData, handleCreateNewEvent }: ClassFormProps) {
  const [repeatClassCadence, setRepeatClassCadence] = useState('');
  const [showRepeatClass, setShowRepeatClass] = useState(false);

  const formik = useFormik({
    initialValues: {
      userId: user?.id,
      newEventName: '',
      newEventDescription: '',
      newEventDuration: '',
      newEventLimit: 0,
      clubName: user?.club_name,
      date: DateTime.now().setZone(user?.timezone),
      repeatEvent: '',
      repeatEventAmount: 0,
      epochDate: DateTime.now().setZone(user?.timezone).toUTC().toMillis(),
    },
    validationSchema: createClassSchema,
    onSubmit: (values, { resetForm }) => {
      handleCreateNewEvent(values, resetForm);
    },
  });

  const updateRepeatClassCadence = (newValue: string) => {
    let cadence;
    switch (newValue) {
      case 'daily':
        cadence = 'days';
        setShowRepeatClass(true);
        break;
      case 'weekly':
        cadence = 'weeks';
        setShowRepeatClass(true);
        break;
      default:
        cadence = '';
        setShowRepeatClass(false);
        break;
    }

    formik.setFieldValue('repeatEvent', newValue);
    setRepeatClassCadence(cadence);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-GB">
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ mt: 1 }}>
          {/* Class Name */}
          <FormControl fullWidth error={formik.touched.newEventName && Boolean(formik.errors.newEventName)}>
            <InputLabel id="newEventName-label">Class Name</InputLabel>
            <Select
              labelId="newEventName-label"
              id="newEventName"
              label="Class Name"
              name="newEventName"
              displayEmpty
              value={formik.values.newEventName}
              onChange={(event) => {
                formik.handleChange(event);
                const selectedClass = classTypeData.find(classType => classType.name === event.target.value);
                if (selectedClass) {
                  formik.setFieldValue("newEventDescription", selectedClass.description);
                }
              }}
            >
              {classTypeData && classTypeData.length > 0 && (
                classTypeData.map((classType) => (
                  <MenuItem key={classType.id} value={classType.name}>
                    {classType.name}
                  </MenuItem>
                ))
              )}
            </Select>
            <FormHelperText>{formik.touched.newEventName && formik.errors.newEventName}</FormHelperText>
          </FormControl>

          {/* Class Description */}
          <FormControl sx={{ mt: 2 }} fullWidth>
            <TextField
              id="newEventDescription"
              label="Class Description"
              name="newEventDescription"
              multiline
              rows={4}
              value={formik.values.newEventDescription}
              onChange={formik.handleChange}
              error={formik.touched.newEventDescription && Boolean(formik.errors.newEventDescription)} // Set the error state here
              helperText={formik.touched.newEventDescription && formik.errors.newEventDescription} // Move the helper text to the TextField
            />
          </FormControl>

          {/* Class Duration */}
          <FormControl sx={{ mt: 2 }} fullWidth error={formik.touched.newEventDuration && Boolean(formik.errors.newEventDuration)}>
            <InputLabel id="newEventDuration-label">Class Duration</InputLabel>
            <Select
              labelId="newEventDuration-label"
              id="newEventDuration"
              fullWidth
              displayEmpty
              label="Class Duration"
              name="newEventDuration"
              value={formik.values.newEventDuration}
              onChange={formik.handleChange}
            >
              <MenuItem value={15}>15 Mins</MenuItem>
              <MenuItem value={30}>30 Mins</MenuItem>
              <MenuItem value={45}>45 Mins</MenuItem>
              <MenuItem value={60}>60 Mins</MenuItem>
              <MenuItem value={90}>90 Mins</MenuItem>
              <MenuItem value={120}>2 Hours</MenuItem>
            </Select>
            <FormHelperText>{formik.touched.newEventDuration && formik.errors.newEventDuration}</FormHelperText>
          </FormControl>

          {/* Class Limit */}
          <FormControl sx={{ mt: 2 }} fullWidth>
            <TextField
              id="newEventLimit"
              label="Class Limit"
              fullWidth
              value={formik.values.newEventLimit}
              onChange={formik.handleChange}
              error={formik.touched.newEventLimit && Boolean(formik.errors.newEventLimit)}
              helperText={formik.touched.newEventLimit && formik.errors.newEventLimit}
              InputProps={{
                inputProps: { min: 0 }, // This ensures the number doesn't go below 0
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton 
                      onClick={() => {
                        const currentValue = parseInt(formik.values.newEventLimit.toString(), 10) || 0;
                        if (currentValue > 0) { // Only decrement if the current value is greater than 0
                          formik.setFieldValue('newEventLimit', (currentValue - 1).toString());
                        }
                      }}
                      edge="end"
                      aria-label="decrement"
                    >
                      <RemoveIcon />
                    </IconButton>
                    <IconButton 
                      onClick={() => {
                        const currentValue = parseInt(formik.values.newEventLimit.toString(), 10) || 0;
                        formik.setFieldValue('newEventLimit', (currentValue + 1).toString());
                      }}
                      edge="end"
                      aria-label="increment"
                    >
                      <AddIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>

          {/* Event Date/Time */}
          <FormControl sx={{ mt: 2 }} fullWidth>
            <DateTimePicker
              label="Class Date"
              value={formik.values.date}
              onChange={(value): void => {
                if (value) {
                  formik.setFieldValue('date', value.setZone(user?.timezone));
                  formik.setFieldValue('epochDate', value.setZone(user?.timezone).toUTC().toMillis());
                }
              }}
            />
          </FormControl>

          {/* Repeat Class */}
          <FormControl sx={{ mt: 2 }} fullWidth error={formik.touched.repeatEvent && Boolean(formik.errors.repeatEvent)}>
            <InputLabel id="repeatEvent-label">Repeat Class</InputLabel>
            <Select
              labelId="repeatEvent-label"
              id="repeatEvent"
              displayEmpty
              label="Repeat Class"
              name="repeatEvent"
              value={formik.values.repeatEvent}
              onChange={(event) => {
                formik.handleChange(event); // Call Formik's handleChange
                updateRepeatClassCadence(event.target.value); // Call updateRepeatClassCadence with the new value
              }}
            >
              <MenuItem value="none">None</MenuItem>
              <MenuItem value="daily">Daily</MenuItem>
              <MenuItem value="weekly">Weekly</MenuItem>
            </Select>
            <FormHelperText>{formik.touched.repeatEvent && formik.errors.repeatEvent}</FormHelperText>
          </FormControl>

          {showRepeatClass && (
            // Repeat Class Cycle
            <FormControl sx={{ mt: 2 }} fullWidth>
              <TextField
                id="repeatEventAmount"
                fullWidth
                label="Repeat Length"
                value={`${formik.values.repeatEventAmount.toString()} ${repeatClassCadence}`}
                onChange={formik.handleChange}
                error={formik.touched.repeatEventAmount && Boolean(formik.errors.repeatEventAmount)}
                helperText={formik.touched.repeatEventAmount && formik.errors.repeatEventAmount}
                InputProps={{
                  inputProps: { min: 0 }, // This ensures the number doesn't go below 0
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton 
                        onClick={() => {
                          const currentValue = parseInt(formik.values.repeatEventAmount.toString(), 10) || 0;
                          if (currentValue > 0) {
                            formik.setFieldValue('repeatEventAmount', (currentValue - 1).toString());
                          }
                        }}
                        edge="end"
                        aria-label="decrement"
                      >
                        <RemoveIcon />
                      </IconButton>
                      <IconButton 
                        onClick={() => {
                          const currentValue = parseInt(formik.values.repeatEventAmount.toString(), 10) || 0;
                          formik.setFieldValue('repeatEventAmount', (currentValue + 1).toString());
                        }}
                        edge="end"
                        aria-label="increment"
                      >
                        <AddIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          )}
        </Box>
        {/* Create Class Button */}
        <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
          <Button
            type="submit"
            color="primary"
            variant="outlined"
            disabled={formik.isSubmitting}
          >
            Create Class
          </Button>
        </Box>
      </form>
    </LocalizationProvider>
  );
}