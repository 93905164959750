import { Avatar, Box, Card, CardContent, Grid, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { BookingAttendeesProps } from '../../types/types';

export default function BookingAttendees({
  user,
  selectedUsers,
  handleMemberSelect,
}: BookingAttendeesProps) {
  return (
    <Box sx={{ marginTop: '15px' }}>
      <Typography fontWeight={'fontWeightMedium'}>Who is this booking for (select at least one)?</Typography>
      {/* Grid container for all user cards */}
      <Grid container spacing={2} sx={{ mt: 1 }}>
        {/* Main user card */}
        <Grid item xs={6}>
          <Card
            onClick={() => {
                handleMemberSelect(user?.id ?? '');
            }}
            sx={{
              border: selectedUsers.includes(user?.id ?? '') ? '2px solid #1976d2' : 'inherit',
              backgroundColor: selectedUsers.includes(user?.id ?? '') ? 'rgba(25, 118, 210, 0.08)' : 'inherit',
              transition: 'all 0.2s ease'
            }}
          >
            <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Avatar src={user?.member_profile_image_url}>
                {!user?.member_profile_image_url && <PersonIcon />}
              </Avatar>
              <Typography sx={{ mt: 1 }}>{user?.first_name} {user?.last_name}</Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Family member cards */}
        {user?.family_members?.map(member => (
          <Grid item xs={6} key={member.id}>
            <Card
              onClick={() => {
                  handleMemberSelect(member.id);
              }}
              sx={{
                border: selectedUsers.includes(member.id) ? '2px solid #1976d2' : 'inherit',
                backgroundColor: selectedUsers.includes(member.id) ? 'rgba(25, 118, 210, 0.08)' : 'inherit',
                transition: 'all 0.2s ease'
              }}
            >
              <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Avatar src={member?.member_profile_image_url}>
                  {!member?.member_profile_image_url && <PersonIcon />}
                </Avatar>
                <Typography sx={{ mt: 1 }}>{member.first_name} {member.last_name}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}