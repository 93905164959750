export const verfifyResetCode = async (values: { code: string }, email: string) => {
  const url = `${import.meta.env.VITE_API_ENDPOINT}/auth/api/verify_reset_code`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({ ...values, email }),
  });
  const json = await response.json();
  return json;
}

export const forgotPassword = async (values: { email: string }) => {
  const url = `${import.meta.env.VITE_API_ENDPOINT}/auth/api/forgot_password`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(values),
  });
  const json = await response.json();
  return json;
}

export const changePassword = async (values: { password: string, passwordConfirmation: string }, email: string) => {
    const url = `${import.meta.env.VITE_API_ENDPOINT}/auth/api/change_password`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ ...values, email }),
    });
    const json = await response.json();
    return json;
  }