import React from 'react';
import { 
  Avatar, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  Divider, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  Typography, 
  IconButton 
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import { AttendeesDialogProps } from '../../types/types';

const AttendeesClassList: React.FC<AttendeesDialogProps> = ({
  open,
  onClose,
  attendees,
  isAttendeesLoading = false,
  dialogTitle = 'Class Attendees'
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="body2" fontWeight={'bold'}>
          {dialogTitle}
        </Typography>
        <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />

      <DialogContent>
        {isAttendeesLoading ? (
          <Typography>Loading attendees...</Typography>
        ) : attendees && attendees.length > 0 ? (
          <List sx={{ p: 0 }}>
            {attendees.map((attendee: any, index: number) => (
              <ListItem key={attendee.id || index} sx={{ px: 0 }}>
                <ListItemIcon sx={{ minWidth: 40 }}>
                <Avatar
                      src={
                        attendee.family_member_id
                          ? attendee.family_member_details?.member_profile_image_url  // Use family member image if available
                          : attendee.user_profile_image_url                          // Otherwise use user image
                      }
                      sx={{ width: 32, height: 32, backgroundColor: 'primary.main' }}
                    >
                      {!((attendee.family_member_id ?
                        attendee.family_member_details?.member_profile_image_url :
                        attendee.user_profile_image_url)) &&
                        <PersonIcon fontSize="small" />
                      }
                    </Avatar>
                </ListItemIcon>
                <ListItemText
                    primary={
                      attendee.family_member_id
                        ? (attendee.family_member_details?.first_name + ' ' + attendee.family_member_details?.last_name)
                        : (attendee.user_first_name + ' ' + attendee.user_last_name)
                    }
                  />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="body2" color="text.secondary" align="center" sx={{ py: 2 }}>
            No attendees found for this class.
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AttendeesClassList;