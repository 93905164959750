import React, { useEffect, useState } from 'react';
import { Snackbar, Button, SnackbarCloseReason } from '@mui/material';

const ServiceWorkerUpdate: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null);
  const [swVersion, setSwVersion] = useState<string | null>(null);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistration().then((reg) => {
        if (!reg) return;

        // Check if there's already a waiting service worker
        if (reg.waiting) {
          setWaitingWorker(reg.waiting);
          setOpen(true);
        }

        reg.onupdatefound = () => {
          const installingWorker = reg.installing;
          if (installingWorker) {
            installingWorker.onstatechange = () => {
              if (installingWorker.state === 'installed' && navigator.serviceWorker.controller) {
                setWaitingWorker(installingWorker);
                setOpen(true);
              }
            };
          }
        };
      }).catch(error => console.error(`Service worker registration failed: ${error}`));
    }


    // Request the SW version
    if (navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({ type: 'GET_SW_VERSION' });
    }

    // Ensure we listen for messages from SW
    const onMessage = (event: MessageEvent) => {

      if (event.data && event.data.type === 'UPDATE_AVAILABLE') {
        setOpen(true);
      }

      if (event.data && event.data.type === 'SW_VERSION') {
        console.log(`✅ Running Service Worker Version: ${event.data.version}`);
        setSwVersion(event.data.version);
      }
    };

    navigator.serviceWorker.addEventListener('message', onMessage);


    return () => {
      navigator.serviceWorker.removeEventListener('message', onMessage);
    };
  }, []);

  const handleClose = (
    event: React.SyntheticEvent<any, Event> | Event,
    reason: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };

  const handleRefresh = () => {
    if (waitingWorker) {
      waitingWorker.postMessage({ type: 'SKIP_WAITING' });
      setOpen(false);
    }
  };

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      message={`A new version (${swVersion}) is available!`}
      action={
        <>
          <Button color="primary" size="small" onClick={handleRefresh}>
            REFRESH
          </Button>
          <Button color="primary" size="small" onClick={() => setOpen(false)}>
            DISMISS
          </Button>
        </>
      }
    />
  );
};

export default ServiceWorkerUpdate;