// MUI Components
import { Box, Typography } from '@mui/material';

export default function BookingDescription({ classData }: any) {
    return (
        <Box sx={{ marginTop: '15px' }}>
            <Typography fontWeight={'fontWeightMedium'}>About This Class:</Typography>
            <Typography sx={{ marginTop: '10px' }}>{classData.description}</Typography>
        </Box>
    );
}