// Global Context
import { useAlert } from '../AlertContext';
// Hooks
import { useShowAlert } from "../hooks/useShowAlert";
import useClubRedirect from '../hooks/useClubRedirect';
// Global Context
import { useUser } from '../context/UserContext';
// Api
import { createNewTournament } from '../api/Tournaments';
// Helpers
import { scrollToTop } from '../helpers/Utilities';
// Components
import Loading from "../components/Alerts/Loading";
import AlertMessage from '../components/Alerts/AlertMessage';
import NewTournamentForm from '../components/Tournaments/Forms/NewTournamentForm';
// Material-UI
import { Box, Container, Typography } from '@mui/material';

export default function CreateTournament() {
  const { user, isUserLoading } = useUser();
  const { alert } = useAlert();
  const showAlert = useShowAlert();

  // Redirect user if not a club user
  useClubRedirect(user, isUserLoading);
  
  const handleCreateNewTournament = async (data: any, resetForm: () => void) => {
    try {
      const response = await createNewTournament(data);
      if (response.success) {
        scrollToTop();
        showAlert(response.success.message, response.success.subMessage, 'success');
        resetForm();
      }

      if (response.error) {
        showAlert(response.error.message, response.error.subMessage, 'error');
      }
    } catch (error) {
      showAlert('Error', 'Please try again.', 'error');
    }
  };

  if (isUserLoading) {
    return <Loading text="" />
  }

    return (
      <Container component="main" maxWidth="sm" sx={{ pt: '64px' }}>
        {alert && (
          <AlertMessage />
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: 800,
            overflow: "hidden",
            overflowY: "scroll",
          }}
        >
        <Typography sx={{ paddingTop: 2 }}>Create New Event/Tournament</Typography>
        <NewTournamentForm user={user} handleCreateNewTournament={handleCreateNewTournament} />
      </Box>
      </Container>  
    );
}