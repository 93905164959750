import React, { useState } from 'react';
import { useUser } from '../../context/UserContext';
import { Link as RouterLink } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

const FooterNav: React.FC = () => {
  const [value, setValue] = useState(0);

  const { user } = useUser();
  if (!user) return null;

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
    >
      <BottomNavigationAction sx={{ textDecoration: 'none' }} component={RouterLink} to="/" icon={<HomeIcon />} disableRipple />
      <BottomNavigationAction sx={{ textDecoration: 'none' }} component={RouterLink} to={user?.user_type === 'club' ? '/searchclasses' : '/bookclasses'} icon={<SearchIcon />} disableRipple />
      <BottomNavigationAction sx={{ textDecoration: 'none' }} component={RouterLink} to="/profile" icon={<AccountBoxIcon />} disableRipple />
    </BottomNavigation>
  );
}

export default FooterNav;
