// Libs
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query'
import { Helmet } from 'react-helmet';
// Global Context
import { useUser } from "../context/UserContext";
import useRedirectIfNoUser from '../hooks/useRedirectIfNoUser';
// Api Requests
import { getTournamentsByClubName } from "../api/Tournaments";
// Types
import { Tournament } from "../types/types";
// Components
import Loading from "../components/Alerts/Loading";
import TournamentList from "../components/Tournaments/TournamentList";
// Material-UI
import { Box, Button, Card, CardContent, Container, Typography } from '@mui/material';
// Material-UI Icons
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

export default function UpcomingTournaments() {
  const navigate = useNavigate();
  const { user, isUserLoading } = useUser();

  // Restrict access to page if not user
  useRedirectIfNoUser(user, isUserLoading);

  // useQuery hook from react-query to fetch todays classes
  const { data: tournaments, error, isLoading } = useQuery({
    queryKey: ['tournamentsData', user?.club_name],
    queryFn: () => getTournamentsByClubName(user?.club_name ?? ''),
    enabled: !!user?.club_name,
  });

  // If still loading, show loading state
  if (isUserLoading || isLoading) {
    return <Loading text="" />
  }

  if (error) return <p>An error has occurred: {error.message}</p>;

  return (
    <>
    <Helmet>
      <title>{`${user?.club_name || ''} | Upcoming Events`}</title>
    </Helmet>
    <Container component="main" maxWidth="sm" sx={{ overflow: 'auto', paddingBottom: '100px', pt: '64px' }}>
        <Box textAlign={'center'} sx={{marginTop: '5px'}}>
          <Typography variant='body1' fontWeight='600' sx={{padding: '10px'}}>Upcoming Events</Typography>
        </Box>
        {/* If no tournaments show home button */}
        {tournaments?.length === 0 ? (
          <Box textAlign={'center'} sx={{marginTop: '15px'}}>
          <Card >
            <CardContent>
              <Box>
                <Typography fontWeight={'fontWeightLight'}>No Events Found</Typography>
                <EmojiEventsIcon fontSize="large"/>
              </Box>
              <Button variant="contained" onClick={() => navigate('/userlanding')}>Home</Button>
            </CardContent>
          </Card>
        </Box>
        ) : (
          <>
          {tournaments?.map((tournament: Tournament) => (
            <TournamentList key={tournament.id} tournament={tournament} user={user} navigate={navigate} />
          ))}
          </>
        )}
      </Container>
      </>
    );
};
