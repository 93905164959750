import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { User } from '../types/types';

const useMemberRedirect = (user: User | null, isUserLoading: boolean) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isUserLoading) {
      if (!user) {
        navigate('/');
        return;
      }
      if (user?.user_type !== 'member') {
        navigate('/clublanding');
        return;
      }
    }
  }, [user, isUserLoading, navigate]);
};

export default useMemberRedirect;
