// Types
import { Event } from "../types/types";
// Helpers
import { getAllClassesGroupedByDate, getTodaysClasses } from '../helpers/Utilities';

export const sendCancelEventRequest = async (userId: string, eventId: string) => {
    const url = `${import.meta.env.VITE_API_ENDPOINT}/events/api/cancel_event`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      Accept: 'application/json',
    },
      body: JSON.stringify({userId, eventId}),
    });
    const json = await response.json();
    return json;
};

export const getEventById = async (eventId: string) => {
  const url = `${import.meta.env.VITE_API_ENDPOINT}/events/api/get_event_by_id`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    Accept: 'application/json',
  },
    body: JSON.stringify({eventId}),
  });
  const json = await response.json();
  const data = json as Event;
  return data
};

export const getEventsByRepeatId = async (repeatEventId: string) => {
  const url = `${import.meta.env.VITE_API_ENDPOINT}/events/api/get_events_by_repeat_id`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    Accept: 'application/json',
  },
    body: JSON.stringify({repeatEventId}),
  });
  const json = await response.json();
  const data = json as Event[];
  return data
};

export const getEventsByClubName = async (clubName: string, timezone: string) => {
  const url = `${import.meta.env.VITE_API_ENDPOINT}/events/api/get_events_by_club_name`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    Accept: 'application/json',
  },
    body: JSON.stringify({clubName}),
  });
  const json = await response.json();
  const data = getAllClassesGroupedByDate(timezone, json) as [string, Event[]][];
  return data
};

export const getTodaysEventsByClubName = async (clubName: string, timezone: string) => {
  const url = `${import.meta.env.VITE_API_ENDPOINT}/events/api/get_events_by_club_name`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    Accept: 'application/json',
  },
    body: JSON.stringify({clubName}),
  });
  const json = await response.json();
  const data = getTodaysClasses(timezone, json) as Event[];
  return data
};

export const createNewEvent = async (data: any) => {
  const url = `${import.meta.env.VITE_API_ENDPOINT}/events/api/new_event`;
  const response = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
};