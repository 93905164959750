import { Helmet } from 'react-helmet';
// Global Context
import { useUser } from '../context/UserContext';
import { useAlert } from '../AlertContext';
// Components
import Loading from "../components/Alerts/Loading";
import AlertMessage from '../components/Alerts/AlertMessage';
// API Requests
import { sendTestNotification } from '../api/Notifications';
// Hooks
import useClubRedirect from '../hooks/useClubRedirect';
import { useShowAlert } from "../hooks/useShowAlert";
// Firebase
import { messaging, getToken } from '../firebase/firebaseConfig';
// Material-UI
import { Box, Button, Container } from '@mui/material';

export default function CreateNotification() {
  const { user, isUserLoading } = useUser();
  const { alert } = useAlert();
  const showAlert = useShowAlert();

  useClubRedirect(user, isUserLoading);

  const sendTest = async () => {
    try {
      const response = await sendTestNotification();
      if (response.success) {
        showAlert(response.success.message, response.success.subMessage, 'success');
      } else {
        showAlert(response.error.message, response.error.subMessage, 'error');
      }
    } catch (error) {
        showAlert('An error has occurred', 'Please Try Again', 'error');
    }
  };

  if (isUserLoading) {
    return <Loading text="" />
  }

  return (
    <>
      <Helmet>
        <title>{`${user?.club_name || ''} | Create Notification`}</title>
      </Helmet>
      <Container component="main" maxWidth="xs" sx={{ pt: '64px' }}>
      {alert && (
        <AlertMessage />
      )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: 675,
            alignItems: 'center',
            overflow: "hidden",
            overflowY: "scroll",
          }}
        >
          <Button sx={{ marginTop: 2 }} variant="contained" onClick={sendTest}>Send Test Notification</Button>
        </Box>
      </Container>
    </>
  );
}