import { useFormik } from 'formik';
import { DateTime } from 'luxon';
import { Box, Button, FormControl, TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { createTournamentSchema } from '../../../validations/CreateTournamentSchema';

interface NewTournamentFormProps {
  user: any;
  handleCreateNewTournament: (values: any, resetForm: () => void) => void;
}

export default function NewTournamentForm({ user, handleCreateNewTournament }: NewTournamentFormProps) {
  const formik = useFormik({
    initialValues: {
      user_id: user?.id,
      name: '',
      description: '',
      club_name: user?.club_name,
      address: '',
      google_maps_lat: '',
      google_maps_lng: '',
      link: '',
      date: DateTime.now().setZone(user?.timezone),
      epoch_date: DateTime.now().toUTC().toMillis(),
    },
    validationSchema: createTournamentSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        await handleCreateNewTournament(values, resetForm);
      } finally {
        setSubmitting(false); // Ensure that Formik knows the submission is complete
      }
    },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-GB">
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ mt: 1 }}>
          {/* Tournament Name */}
          <FormControl fullWidth error={formik.touched.name && Boolean(formik.errors.name)}>
            <TextField
              id="name"
              label="Name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)} // Set the error state here
              helperText={formik.touched.name && formik.errors.name} // Move the helper text to the TextField
            />
          </FormControl>

          {/* Tournament Description */}
          <FormControl sx={{ mt: 2 }} fullWidth>
            <TextField
              id="description"
              label="Description"
              name="description"
              multiline
              rows={4}
              value={formik.values.description}
              onChange={formik.handleChange}
              error={formik.touched.description && Boolean(formik.errors.description)} // Set the error state here
              helperText={formik.touched.description && formik.errors.description} // Move the helper text to the TextField
            />
          </FormControl>

          {/* Tournament Link */}
          <FormControl sx={{ mt: 2 }} fullWidth>
            <TextField
              id="link"
              label="Link"
              name="link"
              value={formik.values.link}
              onChange={formik.handleChange}
              error={formik.touched.link && Boolean(formik.errors.link)} // Set the error state here
              helperText={formik.touched.link && formik.errors.link} // Move the helper text to the TextField
            />
          </FormControl>

          {/* Tournament Address */}
          <FormControl sx={{ mt: 2 }} fullWidth error={formik.touched.address && Boolean(formik.errors.address)}>
            <TextField
              id="address"
              label="Address"
              name="address"
              value={formik.values.address}
              onChange={formik.handleChange}
              error={formik.touched.address && Boolean(formik.errors.address)} // Set the error state here
              helperText={formik.touched.address && formik.errors.address} // Move the helper text to the TextField
            />
          </FormControl>

          {/* Google Maps Lat */}
          <FormControl sx={{ mt: 2 }} fullWidth error={formik.touched.google_maps_lat && Boolean(formik.errors.google_maps_lat)}>
            <TextField
              id="google_maps_lat"
              label="Google Maps Lat"
              name="google_maps_lat"
              value={formik.values.google_maps_lat}
              onChange={formik.handleChange}
              error={formik.touched.google_maps_lat && Boolean(formik.errors.google_maps_lat)} // Set the error state here
              helperText={formik.touched.google_maps_lat && formik.errors.google_maps_lat} // Move the helper text to the TextField
            />
          </FormControl>

          {/* Google Maps Lng */}
          <FormControl sx={{ mt: 2 }} fullWidth error={formik.touched.google_maps_lng && Boolean(formik.errors.google_maps_lng)}>
            <TextField
              id="google_maps_lng"
              label="Google Maps Lng"
              name="google_maps_lng"
              value={formik.values.google_maps_lng}
              onChange={formik.handleChange}
              error={formik.touched.google_maps_lng && Boolean(formik.errors.google_maps_lng)} // Set the error state here
              helperText={formik.touched.google_maps_lng && formik.errors.google_maps_lng} // Move the helper text to the TextField
            />
          </FormControl>

          {/* Event Date/Time */}
          <FormControl sx={{ mt: 2 }} fullWidth>
            <DateTimePicker
              label="Date"
              value={formik.values.date}
              onChange={(value): void => {
                if (value) {
                  formik.setFieldValue('date', value.setZone(user?.timezone));
                  formik.setFieldValue('epoch_date', value.setZone(user?.timezone).toUTC().toMillis());
                }
              }}
            />
          </FormControl>
        </Box>
        {/* Create Class Button */}
        <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
          <Button type="submit" color="primary" variant="outlined" disabled={formik.isSubmitting}>
            Create
          </Button>
        </Box>
      </form>
    </LocalizationProvider>
  );
}