import { CircularProgress, Box, Typography } from '@mui/material';

export default function Loading ({text}: {text: string}) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh', // take full viewport height
        backgroundColor: 'white'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 2 }}>
        <CircularProgress color="primary" data-testid="loading-spinner" />
        <Typography variant="h6" color="primary" data-testid="loading-text">
          {text}
        </Typography>
      </Box>
    </Box>
  );
};
