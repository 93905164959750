// Libs
import { useState } from "react";
import { useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query'
// Global Context
import { useAlert } from '../AlertContext';
import { useUser } from "../context/UserContext";
// Hooks
import { useShowAlert } from "../hooks/useShowAlert";
import useMemberRedirect from '../hooks/useMemberRedirect';
// Api Requests
import { getEventById, getEventsByRepeatId } from '../api/Events';
import { getBookingsByEventId, getBookingsByRepeatEventId, createNewBooking } from '../api/Bookings';
// Types
import { Booking, FamilyMember, User } from "../types/types";
// Components
import Loading from "../components/Alerts/Loading";
import AlertMessage from '../components/Alerts/AlertMessage';
import ClassSummaryHeader from "../components/Bookings/ClassSummaryHeader";
import BookingDescription from "../components/Bookings/BookingDescription";
import BookingAttendees from "../components/Bookings/BookingAttendees";
import ExistingBookings from "../components/Bookings/ExisitingBookings";
// Material-UI
import { Box, Button, Container } from '@mui/material';
// Material-UI Icons
import BookingSummary from "../components/Bookings/BookingSummary";
import ClassSeriesSelection from "../components/Bookings/ClassSeriesSelection";


/**
 * Calculate the number of spaces left in a class after accounting for booked spaces and current selections
 * @param eventLimit The maximum capacity of the event
 * @param spacesBooked The number of spaces already booked
 * @param selectedUsers Array of selected user IDs for the booking
 * @returns The number of spaces left in the class
 */
const getSpacesLeft = (eventLimit: number, spacesBooked: number, selectedUsers: string[]) => {
  return eventLimit - spacesBooked - selectedUsers.length;
};

/**
 * Check if booking is allowed based on spaces left across current class and series
 * @param classData The main class data
 * @param classSeries Array of classes in the series
 * @param selectedClasses Array of selected classes in the series
 * @param selectedUsers Array of selected user IDs for the booking
 * @returns Boolean indicating if booking is allowed
 */
const isBookingAllowed = (
  classData: any,
  classSeries: any[] | undefined,
  selectedClasses: { eventId: string, date: string }[],
  selectedUsers: string[]
) => {
  // Check if main class has enough spaces
  if (getSpacesLeft(classData.event_limit, classData.spaces_booked, selectedUsers) < 0) {
    return false;
  }

  // Check if all selected series classes have enough spaces
  if (classSeries && selectedClasses.length > 0) {
    for (const selectedClass of selectedClasses) {
      const seriesClass = classSeries.find(cls => cls.id.toString() === selectedClass.eventId);
      if (seriesClass && getSpacesLeft(seriesClass.event_limit, seriesClass.spaces_booked, selectedUsers) < 0) {
        return false;
      }
    }
  }

  return true;
};

export default function BookClass() {
  const { alert } = useAlert();
  const showAlert = useShowAlert();
  const { eventId } = useParams();
  const { user, isUserLoading } = useUser();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectedClasses, setSelectedClasses] = useState<{ eventId: string, date: string }[]>([]);
  // Redirect user if not a member user
  useMemberRedirect(user, isUserLoading);

  const handleMemberSelect = (memberId: string) => {
    setSelectedUsers((prevCheckedUsers: any) => {
      if (prevCheckedUsers.includes(memberId)) {
        // Uncheck the checkbox
        return prevCheckedUsers.filter((id: string) => id !== memberId);
      } else {
        // Check the checkbox
        return [...prevCheckedUsers, memberId];
      }
    });
  };

  const handleClassSelection = (classId: string, date: string) => {
    setSelectedClasses((prev) => {
      const exists = prev.find((cls) => cls.eventId === classId);
      if (exists) {
        return prev.filter((cls) => cls.eventId !== classId);
      } else {
        return [...prev, { eventId: classId, date }];
      }
    });
  };

  const queryClient = useQueryClient();
  const { data: classData, error: classError, isLoading: isClassLoading } = useQuery({
    queryKey: ['eventsData', eventId,],
    queryFn: () => getEventById(eventId ?? ''),
    enabled: !!eventId,
  });

  // Retrieve existing bookings for this class
  const { data: bookedMembers, error: existingBookingsError, isLoading: isExistingBookingsLoading } = useQuery({
    queryKey: ['existingBookings', eventId],
    queryFn: () => getBookingsByEventId(eventId ?? ''),
    enabled: !!eventId,
  });

  // Retrieve existing bookings for this series
  const { data: seriesBookings, error: seriesBookingsError, isLoading: isSeriesBookingsLoading } = useQuery({
    queryKey: ['seriesBookings', classData?.repeat_event_id],
    queryFn: async () => {
      // Get all bookings for the series
      const allSeriesBookings = await getBookingsByRepeatEventId(classData?.repeat_event_id ?? '');

      // If no user or no bookings, return empty array
      if (!user || !allSeriesBookings) return [];

      // Filter bookings to only include those for the current user and family
      return allSeriesBookings.filter(booking => {
        // Check if the booking is for the current user
        if (booking.user_id === user.id) return true;

        // Check if the booking is for a family member of the current user
        if (booking.family_member_id &&
          user.family_members?.some(member => member.id === booking.family_member_id)) {
          return true;
        }

        return false;
      });
    },
    enabled: !!classData?.repeat_event_id && !!user?.id,
  });

  // Fetch all classes in the series
  const { data: classSeries, isLoading: isSeriesLoading, error: seriesError } = useQuery({
    queryKey: ["seriesClasses", classData?.repeat_event_id],
    queryFn: async () => {
      return await getEventsByRepeatId(classData?.repeat_event_id ?? "");
    },
    enabled: !!classData?.repeat_event_id,
  });

  const isSubmitButtonDisabled =
    selectedUsers.length === 0 ||
    (classSeries && classSeries.length > 0 && selectedClasses.length === 0);

  const handleCreateNewBookingRequest = async (body: any) => {
    try {
      const response = await createNewBooking(body);

      if (response.success) {
        window.scrollTo(0, 0);
        showAlert(response.success.message, response.success.subMessage, 'success');
        queryClient.invalidateQueries({ queryKey: ['existingBookings', eventId] });
        queryClient.invalidateQueries({ queryKey: ['eventsData', eventId] });
        queryClient.invalidateQueries({ queryKey: ['seriesClasses', classData?.repeat_event_id] });
        queryClient.invalidateQueries({ queryKey: ['seriesBookings', classData?.repeat_event_id] });
        setSelectedUsers([]);
        setSelectedClasses([]);
      }

      if (response.error) {
        window.scrollTo(0, 0);
        showAlert(response.error.message, response.error.subMessage, 'error');
        setSelectedUsers([]);
        setSelectedClasses([]);
      }

    } catch (error) {
      window.scrollTo(0, 0);
      showAlert('Something Went Wrong', 'Please Try Again', 'error');
      console.log('Error creating new booking:', error);
      setSelectedUsers([]);
      setSelectedClasses([]);
    }
  }

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    const formBody = {
      eventName: classData?.name,
      eventLimit: classData?.event_limit,
      eventId: eventId,
      userId: user?.id,
      date: classData?.date,
      clubName: classData?.club_name,
      familyToBook: selectedUsers,
      spacesBooked: selectedUsers.length > 0 ? selectedUsers.length : 1,
      classSeries: selectedClasses.length > 0 ? selectedClasses : [],
      repeatEventId: classData?.repeat_event_id,
    };
    handleCreateNewBookingRequest(formBody);
  };

  if (classError) return <p>An error has occurred: {classError.message}</p>;
  if (existingBookingsError) return <p>An error has occurred: {existingBookingsError.message}</p>;
  if (seriesError) return <p>An error has occurred: {seriesError.message}</p>;
  if (seriesBookingsError) return <p>An error has occurred: {seriesBookingsError.message}</p>;

  // If still loading, show loading state
  if (isUserLoading || isClassLoading || isExistingBookingsLoading || isSeriesLoading || isSeriesBookingsLoading) {
    return <Loading text="" />
  }

  return (
    <Container
      component="main"
      maxWidth="md"
      sx={{
        pt: '64px',
        pb: '64px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}>
      {alert && (
        <AlertMessage />
      )}
      <div>
        {classData ? (
          <>
            <ClassSummaryHeader classData={classData} user={user} selectedUsers={selectedUsers} classSeries={classSeries ?? []} />

            <BookingDescription classData={classData} />

            <ExistingBookings bookings={bookedMembers || []} seriesBookings={seriesBookings || []} user={user} />

            <BookingAttendees
              user={user}
              selectedUsers={selectedUsers}
              handleMemberSelect={handleMemberSelect}
            />


            {/* Additional Classes in Series */}
            {classSeries && classSeries.length > 0 && (
              <ClassSeriesSelection classSeries={classSeries} selectedClasses={selectedClasses} setSelectedClasses={setSelectedClasses} selectedUsers={selectedUsers} handleClassSelection={handleClassSelection} userTimeZone={user?.timezone || ''} />
            )}

            {/* Booking Summary */}
            <BookingSummary classData={classData} classSeries={classSeries} user={user} selectedUsers={selectedUsers} selectedClasses={selectedClasses} ></BookingSummary>

            {/* Book Class Button */}
            <form onSubmit={handleFormSubmit}>
              <Box sx={{ marginTop: '15px' }} textAlign={'center'} display={'flex'} justifyContent={'center'}>
                <Button id='submit-book-class' disabled={isSubmitButtonDisabled || !isBookingAllowed(classData, classSeries, selectedClasses, selectedUsers)} type="submit" variant="contained" color="primary">{selectedClasses.length > 1 ? 'Book Classes' : 'Book Class'}</Button>
              </Box>
            </form>
          </>
        ) : (
          <p>No class data</p>
        )}
      </div>
    </Container>
  )
};