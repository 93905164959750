// React
import { useState } from 'react';
// MUI Components
import { Box, Button, Card, CardContent, Checkbox, Divider, Typography } from '@mui/material';
// MUI Icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
// Helpers
import { showFriendlyDate, showFriendlyTime } from '../../helpers/Utilities';
// Types
import { ClassSeriesSelectionProps } from '../../types/types';

export default function ClassSeriesSelection ({ 
  classSeries, 
  selectedClasses, 
  setSelectedClasses,
  selectedUsers, 
  handleClassSelection, 
  userTimeZone 
}: ClassSeriesSelectionProps) {
  if (!classSeries || classSeries.length === 0) return null;
  
  // Add state to track expanded status
  const [showAllClasses, setShowAllClasses] = useState(false);
  
  // Define the number of classes to show initially
  const initialClassesToShow = 4;

  // Calculate spaces left considering whether this class is selected
  const getAdjustedSpacesLeft = (cls: any) => {
    const isSelected = selectedClasses.some(
      (selectedClass) => selectedClass.eventId === cls.id.toString()
    );
    
    // Base space calculation
    let spaces = cls.event_limit - cls.spaces_booked;
    
    // If this class is selected, subtract the number of selected users
    if (isSelected && selectedUsers.length > 0) {
      spaces -= selectedUsers.length;
    }
    
    return spaces;
  };
  
  // Check if there's enough space for the selected users
  const hasEnoughSpace = (eventLimit: number, spacesBooked: number) => {
    const selectedCount = selectedUsers.length || 0;
    return (eventLimit - spacesBooked) >= selectedCount;
  };

  // Determine which classes to display
  const displayedClasses = showAllClasses 
    ? classSeries 
    : classSeries.slice(0, initialClassesToShow);

  // Check if there are more classes to show
  const hasMoreClasses = classSeries.length > initialClassesToShow;
  
  return (
    <Box sx={{ marginTop: "20px", mb: 2 }}>
      <Typography fontWeight="fontWeightMedium" gutterBottom>
        Classes in this Series
      </Typography>
      
      <Box sx={{ 
        border: '1px solid #e0e0e0', 
        borderRadius: 1, 
        p: 2, 
        backgroundColor: '#f5f5f5'
      }}>
        <Box display="flex" alignItems="center" mb={1}>
          <Checkbox
            checked={selectedClasses.length === classSeries.length}
            indeterminate={selectedClasses.length > 0 && selectedClasses.length < classSeries.length}
            disabled={!selectedUsers.length}
            onChange={(e) => {
              if (e.target.checked) {
                // Select all classes that have enough space for the selected users
                const availableClasses = classSeries
                  .filter(cls => hasEnoughSpace(cls.event_limit, cls.spaces_booked))
                  .map(cls => ({
                    eventId: cls.id.toString(),
                    date: cls.date
                  }));
                setSelectedClasses(availableClasses);
              } else {
                // Deselect all classes
                setSelectedClasses([]);
              }
            }}
          />
          <Typography fontWeight="medium">
            {selectedClasses.length === classSeries.length ? "Deselect All" : "Select All Classes"}
          </Typography>
        </Box>
        
        <Divider sx={{ mb: 2 }} />
        
        {displayedClasses.map((cls) => {
          const isSelected = selectedClasses.some(
            (selectedClass) => selectedClass.eventId === cls.id.toString()
          );
          
          // Get spaces left, adjusted for selection
          const spacesLeft = getAdjustedSpacesLeft(cls);
          
          // Check if there's enough space for the selected users
          const enoughSpaceForSelection = hasEnoughSpace(cls.event_limit, cls.spaces_booked);
          
          // Disable if no users selected or not enough space
          const isDisabled = !selectedUsers.length || !enoughSpaceForSelection;
          
          return (
            <Card 
              key={cls.id}
              onClick={() => !isDisabled && handleClassSelection(cls.id.toString(), cls.date)}
              sx={{
                cursor: isDisabled ? 'not-allowed' : 'pointer',
                opacity: isDisabled ? 0.6 : 1,
                mb: 1,
                border: isSelected ? '2px solid #1976d2' : '1px solid #e0e0e0',
                backgroundColor: isSelected ? 'rgba(25, 118, 210, 0.08)' : 'white',
                transition: 'all 0.2s ease'
              }}
            >
              <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 1 }}>
                <Box>
                  <Typography variant="body2" fontWeight="medium">
                    {showFriendlyDate(cls.date, userTimeZone)}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {showFriendlyTime(cls.date, userTimeZone)}
                  </Typography>
                </Box>
                
                <Box display="flex" alignItems="center">
                  <Typography 
                    variant="body2" 
                    color={spacesLeft <= 3 ? 'error.main' : 'text.secondary'}
                    sx={{ mr: 1 }}
                  >
                    {spacesLeft} {spacesLeft === 1 ? 'space' : 'spaces'} left
                  </Typography>
                  {isSelected && <CheckCircleIcon color="primary" />}
                </Box>
              </CardContent>
              
              {!enoughSpaceForSelection && selectedUsers.length > 0 && (
                <Box sx={{ px: 2, pb: 1 }}>
                  <Typography variant="caption" color="error">
                    Not enough space for {selectedUsers.length} {selectedUsers.length === 1 ? 'person' : 'people'}
                  </Typography>
                </Box>
              )}
            </Card>
          );
        })}

        {/* Show More/Less Button */}
        {hasMoreClasses && (
          <Box mt={2} textAlign="center">
            <Button
              variant="text"
              size="small"
              color="primary"
              onClick={() => setShowAllClasses(!showAllClasses)}
              endIcon={showAllClasses ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            >
              {showAllClasses 
                ? "Show Less" 
                : `Show All Classes (${classSeries.length - initialClassesToShow} more)`}
            </Button>
          </Box>
        )}
        
        {selectedClasses.length > 0 && (
          <Box mt={1} textAlign="center">
            <Typography variant="body2" color="primary">
              {selectedClasses.length} additional {selectedClasses.length === 1 ? 'class' : 'classes'} selected
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};