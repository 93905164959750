// Libs
import { Fragment, useState } from "react"
import { useQuery } from '@tanstack/react-query';
// API
import { getBookingsByEventId } from '../../api/Bookings';
// Types
import { CancelClassItem } from "../../types/types";
import { showFriendlyTime } from '../../helpers/Utilities';
// Components
import CancelClassConfirmation from '../../components/Dialog/CancelClassConfirmation';
import AttendeesClassList from "../Dialog/AttendeesClassList";
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography, Button  } from "@mui/material"
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

export default function MemberLandingList({ date, classItems, user, navigate, sendCancelRequest }: { date: string, classItems: any[], user: any, navigate: any, sendCancelRequest: any}) {
  const [isDialogOpen, setDialogOpen] = useState(false);
  // State to keep track of the class item to be cancelled
  const [classToCancel, setClassToCancel] = useState<CancelClassItem | null>(null);
  // State to track which class's bookings are being viewed
  const [selectedClassId, setSelectedClassId] = useState<string | null>(null);

  // Query to fetch bookings when a class is selected
  const { data: attendees, isLoading: isAttendeesLoading } = useQuery({
    queryKey: ['bookings', selectedClassId],
    queryFn: () => getBookingsByEventId(selectedClassId || ''),
    enabled: !!selectedClassId, // Only run query when a class is selected
  });

  // Close the dialog
  const handleCloseAttendees = () => {
    setSelectedClassId(null);
  };

  // Open the dialog for a specific class
  const handleViewAttendees = (classId: string) => {
    setSelectedClassId(classId);
  };

  // Handler to open the dialog and set the class to cancel
  const handleCancelClick = (classItem: any) => {
    setClassToCancel(classItem);
    setDialogOpen(true);
  };

  // Handler for when user confirms cancellation
  const handleConfirmCancellation = () => {
    if (classToCancel) {
      sendCancelRequest(user?.id, classToCancel.family_member_details?.id ?? classToCancel.family_member_id, classToCancel.event_id, classToCancel.club_name);
      // Close the dialog
      setDialogOpen(false);
      // Reset the class to cancel
      setClassToCancel(null);
    }
  };

  // Handler for closing the dialog without action
  const handleCloseDialog = () => {
    setDialogOpen(false);
    setClassToCancel(null);
  };

  return (
    <Fragment key={date}>
      <ListItemIcon sx={{ width: '100%'}}>
        <Box display="flex" alignItems="center" sx={{ width: '100%', backgroundColor: '#eeeeee', padding: 1, display: 'flex', alignItems: 'center', marginBottom: 1 }}>
          <CalendarMonthOutlinedIcon sx={{ fontSize: 'x-large', marginRight: '5px' }} />
          <Typography sx={{ fontWeight: '500', color: 'black', fontSize: '0.975rem' }}>{date}</Typography>
        </Box>
      </ListItemIcon>
      <List>
        {classItems.map((classItem: any) => (
          <ListItem key={classItem.id}>
           <ListItemText primary={
              <>
              <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                <Box display="flex" flexDirection="column" alignItems="center">
                  {/* Time */}
                  <Typography sx={{ fontWeight: '500' }}>{showFriendlyTime(classItem.date, user?.timezone)}</Typography>
                  {/* If class full show full button  */}
                  {classItem.spaces_booked === classItem.event_limit ? (
                    <Button
                      onClick={() => handleViewAttendees(classItem.event_id.toString())}
                      variant="contained"
                      color="error"
                      size="small"
                      sx={{ '&.Mui-disabled': { color: '' } }}>Full</Button>
                  ) : (
                    <Button
                      onClick={() => handleViewAttendees(classItem.event_id.toString())}
                      variant="contained"
                      size="small"
                      sx={{ '&.Mui-disabled': { color: 'black' } }}>{classItem.spaces_booked} / {classItem.event_limit}</Button>
                  )}
                </Box>
                <Box sx={{ width: '180px' }} display="flex" flexDirection="column">
                  {/* Class Name */}
                  <Typography sx={{ fontWeight: 'bold', fontSize: '0.975rem' }}>{classItem.event_name}</Typography>
                  {/* Name of attendee */}
                  {classItem.family_member_details
                   ? `${classItem.family_member_details.first_name} ${classItem.family_member_details.last_name}`
                   : `${user.first_name} ${user.last_name}`
                  }
                  {/* Club Name */}
                  <Typography>{classItem.club_name}</Typography>
                </Box>
                <Box display="flex" flexDirection="row">
                  <Button color="error" variant="contained" size="small" onClick={() => handleCancelClick(classItem)} sx={{ '&:hover': { cursor: 'pointer' } }}>Cancel</Button>
                </Box>
              </Box>
              </>
           }/>
          </ListItem>
        ))}
       </List>
       <AttendeesClassList
        open={!!selectedClassId}
        onClose={handleCloseAttendees}
        attendees={attendees || []}
        isAttendeesLoading={isAttendeesLoading}
      />

       <CancelClassConfirmation 
        open={isDialogOpen} 
        onClose={handleCloseDialog} 
        onConfirm={handleConfirmCancellation} 
        message="Are you sure you want to cancel this class?"
      />
     </Fragment>
    )
}