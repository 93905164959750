import { showFriendlyDate, showFriendlyClassStartEnd } from '../../helpers/Utilities';
import { Card, CardActionArea, CardContent, Chip, Divider, Typography, Box } from '@mui/material';

import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';

export default function ClubLandingList({ todaysClass, user, navigate }: { todaysClass: any, user: any, navigate: any }) {
  
  return (
    <Card
      sx={{
        transition: 'transform 0.2s, box-shadow 0.2s',
        borderRadius: theme => theme.spacing(1),
        mb: 2
      }}
    >
      <CardActionArea onClick={() => navigate(`/attendees/${todaysClass.id}`)}>
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="body1" fontWeight="600" fontSize={18}>{todaysClass.name}</Typography>
            <Chip 
              label={`${todaysClass.spaces_booked}/${todaysClass.event_limit}`}
              color={todaysClass.spaces_booked === todaysClass.event_limit ? 'success' : 'primary'}
              size="medium"
              variant='outlined'
              icon={<PermIdentityOutlinedIcon style={{ fontSize: 16 }} />}
              sx={{ fontWeight: 500, height: '24px' }}
            />
          </Box>
          
          <Divider sx={{ my: 1.5 }} />
          
          <Box mt={2}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 1,
                color: 'text.primary'
              }}
            >
              <CalendarMonthOutlinedIcon sx={{ fontSize: 20, mr: 1.5 }} />
              <Typography variant="body2">
                {showFriendlyDate(todaysClass.date, user?.timezone)}
              </Typography>
            </Box>
            
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 1,
                color: 'text.primary'
              }}
            >
              <AccessTimeOutlinedIcon sx={{ fontSize: 20, mr: 1.5 }} />
              <Typography variant="body2">
                {showFriendlyClassStartEnd(todaysClass.date, todaysClass.duration, user?.timezone)}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}