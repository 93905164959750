import React, { useState } from 'react';
import { Box, Typography, Card, CardContent, Chip, Button, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonIcon from '@mui/icons-material/Person';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { showFriendlyDate, showFriendlyTime } from '../../helpers/Utilities';
import { ExistingBookingsProps } from '../../types/types';
import { Booking } from '../../types/types';

const PersonChip = styled(Chip)(({ theme }) => ({
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    height: 24,
    fontSize: '0.75rem',
    marginLeft: theme.spacing(1),
}));

const ExistingBookings: React.FC<ExistingBookingsProps> = ({
    bookings,
    seriesBookings = {},
    user
}) => {
    const [showAllBookings, setShowAllBookings] = useState(false);

    // Combine current class bookings with series bookings
    const allBookings = React.useMemo(() => {
        // Create a Map to track unique bookings by ID
        const bookingsMap = new Map();

        // Ensure bookings is an array before processing
        const initialBookings = Array.isArray(bookings) ? bookings : [];

        // Create a Set of family member IDs for faster lookup
        const familyMemberIds = new Set();

        // Add current user ID to the set
        if (user && user.id) {
            familyMemberIds.add(user.id);
        }

        // Add family member IDs to the set
        if (user && Array.isArray(user.family_members)) {
            user.family_members.forEach(member => {
                if (member && member.id) {
                    familyMemberIds.add(member.id);
                }
            });
        }

        // Helper function to check if a booking belongs to current user or family
        const isUserOrFamilyBooking = (booking: Booking): boolean => {
            return booking.user_id !== undefined && familyMemberIds.has(booking.user_id);
        };

        // Add initial bookings to the map (filtered by user/family)
        initialBookings
            .filter(booking =>
                booking &&
                typeof booking === 'object' &&
                booking.date &&
                isUserOrFamilyBooking(booking)
            )
            .forEach(booking => {
                const bookingId = booking.id?.toString();
                if (bookingId) {
                    bookingsMap.set(bookingId, booking);
                }
            });

        // Helper function to safely add bookings
        const safelyAddBookings = (bookingsToAdd: any) => {
            if (!Array.isArray(bookingsToAdd)) return;

            bookingsToAdd
                .filter(booking =>
                    booking &&
                    typeof booking === 'object' &&
                    booking.date &&
                    isUserOrFamilyBooking(booking)
                )
                .forEach(booking => {
                    const bookingId = booking.id?.toString();
                    if (bookingId) {
                        bookingsMap.set(bookingId, booking);
                    }
                });
        };

        // Process series bookings if they exist
        if (seriesBookings && typeof seriesBookings === 'object') {
            // Handle array format
            if (Array.isArray(seriesBookings)) {
                safelyAddBookings(seriesBookings);
            }
            // Handle object format with class IDs as keys
            else {
                Object.values(seriesBookings).forEach(classBookings => {
                    safelyAddBookings(Array.isArray(classBookings) ? classBookings : []);
                });
            }
        }

        // Convert map values back to array and sort by date
        const uniqueBookings = Array.from(bookingsMap.values());

        // Sort by date, with additional safety checks
        return uniqueBookings.sort((a, b) => {
            const dateA = a.date ? new Date(a.date).getTime() : 0;
            const dateB = b.date ? new Date(b.date).getTime() : 0;
            return dateA - dateB;
        });
    }, [bookings, seriesBookings, user]);

    // Function to get the name for display
    const getDisplayName = (booking: any) => {
        if (booking.family_member_id) {
            // If we have family member details, use them
            if (booking.family_member_details?.first_name) {
                return booking.family_member_details.first_name;
            }

            // Otherwise try to find the name from the user's family members list
            if (user?.family_members) {
                const familyMember = user.family_members.find(
                    member => member.id === booking.family_member_id
                );
                if (familyMember) {
                    return familyMember.first_name;
                }
            }
            return 'Family Member'; // Fallback
        } else {
            // For user bookings
            if (booking.user_first_name) {
                return booking.user_first_name;
            }

            // If booking is for the current user but name is missing
            if (booking.user_id === user?.id) {
                return user?.first_name || 'You';
            }

            return 'You'; // Default fallback
        }
    };

    // Show only first 2 bookings initially, unless showAllBookings is true
    const displayedBookings = showAllBookings ? allBookings : allBookings.slice(0, 2);
    const hiddenBookingsCount = allBookings.length - 2;

    return (
        <Box sx={{ mb: 3, mt: 3 }}>
            <Typography fontWeight={'fontWeightMedium'} gutterBottom>
                Existing Bookings
            </Typography>

            <Box sx={{
                border: '1px solid #e0e0e0',
                borderRadius: 1,
                p: 2,
                backgroundColor: '#f8f8f8'
            }}>
                {displayedBookings.map((booking, index) => (
                    <Card key={`booking-${booking.id || index}`} sx={{ mb: 1, boxShadow: 'none', border: '1px solid #e0e0e0' }}>
                        <CardContent sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            p: 1.5,
                            '&:last-child': { pb: 1.5 }
                        }}>
                            <Box>
                                <Box display="flex" alignItems="center">
                                    <Typography variant="subtitle2" fontWeight="medium">
                                        {booking.event_name}
                                    </Typography>
                                </Box>

                                <Box display="flex" flexWrap="wrap" gap={1} mt={0.5}>
                                    <Box display="flex" alignItems="center">
                                        <CalendarMonthOutlinedIcon fontSize="small" sx={{ mr: 0.5, color: 'text.secondary', fontSize: '0.875rem' }} />
                                        <Typography variant="body2" color="text.secondary">
                                            {showFriendlyDate(booking.date, user?.timezone)}
                                        </Typography>
                                    </Box>

                                    <Box display="flex" alignItems="center">
                                        <AccessTimeIcon fontSize="small" sx={{ mr: 0.5, color: 'text.secondary', fontSize: '0.875rem' }} />
                                        <Typography variant="body2" color="text.secondary">
                                            {showFriendlyTime(booking.date, user?.timezone)}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>

                            <PersonChip
                                icon={<PersonIcon />}
                                label={getDisplayName(booking)}
                                size="small"
                            />
                        </CardContent>
                    </Card>
                ))}

                {allBookings.length > 2 && (
                    <Button
                        onClick={() => setShowAllBookings(!showAllBookings)}
                        startIcon={showAllBookings ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        sx={{ mt: 1, color: 'primary.main', textTransform: 'none' }}
                    >
                        {showAllBookings
                            ? 'Show fewer bookings'
                            : `Show ${hiddenBookingsCount} more booking${hiddenBookingsCount !== 1 ? 's' : ''}`}
                    </Button>
                )}

                {allBookings.length === 0 && (
                    <Typography variant="body2" color="text.secondary" align="center" sx={{ py: 2, fontStyle: 'italic' }}>
                        You don't have any existing bookings for this class.
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default ExistingBookings;