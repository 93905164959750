import React, { createContext, useContext, useState } from 'react';

export type AlertContent = {
    message: string;
    subMessage: string;
    status: 'success' | 'error';
  }

interface AlertContextType {
  alert: AlertContent | null;
  showAlert: (alert: AlertContent) => void;
  hideAlert: () => void;
}

const AlertContext = createContext<AlertContextType | null>(null);

interface AlertProviderProps {
  children: React.ReactNode;
  initialState?: AlertContent | null;
}

export const useAlert = () => {
  const context = useContext(AlertContext);
    if (!context) {
      throw new Error('useAlert must be used within an AlertProvider');
    }
  return context;
};

export const AlertProvider: React.FC<AlertProviderProps> = ({ children, initialState = null  }) => {
  const [alert, setAlert] = useState<AlertContent | null>(initialState);
  
  const showAlert = (alertContent: AlertContent) => {
    setAlert(alertContent);
  };
  
  const hideAlert = () => {
    setAlert(null);
  };
  
  const contextValue = { alert, showAlert, hideAlert };
  
  return (
    <AlertContext.Provider value={contextValue}>
      {children}
    </AlertContext.Provider>
  );
};


