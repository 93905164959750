import { useEffect } from 'react';
import { messaging } from '../firebase/firebaseConfig';
import { onMessage } from 'firebase/messaging';

const useForegroundFirebaseMessaging = () => {
  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload) => {
      // Customize notification here
      const notificationTitle = payload.data?.title || 'Default title';
      const notificationOptions = {
        body: payload.data?.body || 'Default body',
        icon: payload.data?.icon || '/logo192.png',
        badge: "/favicon.ico",
        vibrate: [200, 100, 200],
      };

      // Show the default browser notification
      new Notification(notificationTitle, notificationOptions);
    });

    return () => {
      unsubscribe();
    };
  }, []);
};

export default useForegroundFirebaseMessaging;