import * as Yup from 'yup';
import { DateTime } from 'luxon';

export const createTournamentSchema = Yup.object().shape({
  name: Yup.string().required('Name Is Required'),
  description: Yup.string().required('Description Is Required'),
  address: Yup.string().required('Address Is Required'),
  google_maps_lat: Yup.string()
    .required('Google Maps Latitude Is Required')
    .matches(/^(-?\d+(\.\d+)?)$/, 'Latitude must be a valid number')
    .test('is-valid-lat', 'Latitude must be between -90 and 90', value => {
      const num = parseFloat(value);
      return num >= -90 && num <= 90;
    }),
  google_maps_lng: Yup.string()
    .required('Google Maps Longitude Is Required')
    .matches(/^(-?\d+(\.\d+)?)$/, 'Longitude must be a valid number')
    .test('is-valid-lng', 'Longitude must be between -180 and 180', value => {
      const num = parseFloat(value);
      return num >= -180 && num <= 180;
    }),
  date: Yup.mixed().test('is-date', 'Date Is Required', value => DateTime.isDateTime(value)),
});
