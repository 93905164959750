import { useFormik } from 'formik';
import { Box, Button, FormControl, TextField } from '@mui/material';
import { createClassTypeSchema } from '../../../validations/CreateClassTypeSchema';

interface ClassTypeFormProps {
  existingEvent: any;
  user: any;
  handleSubmit: (values: any, resetForm: () => void) => void;
}

export default function ClassTypeForm({ existingEvent, user, handleSubmit }: ClassTypeFormProps) {
  const formik = useFormik({
    initialValues: {
      newEventTypeName: existingEvent?.name,
      newEventDescription: existingEvent?.description,
      eventTypeId: existingEvent?.id,
      userId: user?.id,
    },
    validationSchema: createClassTypeSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values, { resetForm }) => {
      handleSubmit(values, resetForm);
      resetForm({
        values: {
          newEventTypeName: '',
          newEventDescription: '',
          eventTypeId: null,
          userId: user?.id,
        },
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box sx={{ mt: 1 }}>
        {/* Class Name */}
        <FormControl sx={{ mt: 2 }} fullWidth>
          <TextField
            id="newEventTypeName"
            label="Class Name"
            name="newEventTypeName"
            value={formik.values.newEventTypeName}
            onChange={formik.handleChange}
            error={formik.touched.newEventTypeName && Boolean(formik.errors.newEventTypeName)}
            helperText={formik.touched.newEventTypeName && formik.errors.newEventTypeName?.toString()}
          />
        </FormControl>

        {/* Class Description */}
        <FormControl sx={{ mt: 2 }} fullWidth>
          <TextField
            id="newEventDescription"
            label="Class Description"
            name="newEventDescription"
            multiline
            rows={4}
            value={formik.values.newEventDescription}
            onChange={formik.handleChange}
            error={formik.touched.newEventDescription && Boolean(formik.errors.newEventDescription)}
            helperText={formik.touched.newEventDescription && formik.errors.newEventDescription?.toString()}
          />
        </FormControl>
      </Box>
      {/* Create Class Button */}
      <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
        <Button type="submit" color="primary" variant="outlined" disabled={formik.isSubmitting}>
          {existingEvent ? 'Update Class Type' : 'Create Class Type'}
        </Button>
      </Box>
    </form>
  );
}