import { Fragment, useState } from "react"
import { useQuery } from '@tanstack/react-query';

import { showFriendlyTime } from '../../helpers/Utilities';

import { Box, Grid, List, ListItem, ListItemText, Typography, Button } from "@mui/material"
// API
import { getBookingsByEventId } from '../../api/Bookings';
// Components
import AttendeesClassList from "../Dialog/AttendeesClassList";
// MUI Icons
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import EventRepeatOutlinedIcon from '@mui/icons-material/EventRepeatOutlined';

export default function BookClassesList({ date, classItems, user, navigate }: { date: string, classItems: any[], user: any, navigate: any }) {

  // State to track which class's bookings are being viewed
  const [selectedClassId, setSelectedClassId] = useState<string | null>(null);

  // Query to fetch bookings when a class is selected
  const { data: attendees, isLoading: isAttendeesLoading } = useQuery({
    queryKey: ['bookings', selectedClassId],
    queryFn: () => getBookingsByEventId(selectedClassId || ''),
    enabled: !!selectedClassId, // Only run query when a class is selected
  });

  // Close the dialog
  const handleClose = () => {
    setSelectedClassId(null);
  };

  // Open the dialog for a specific class
  const handleViewAttendees = (classId: string) => {
    setSelectedClassId(classId);
  };

  return (
    <Fragment>
      {/* Date Header */}
      <Box sx={{ width: '100%', backgroundColor: '#eeeeee', padding: 1, display: 'flex', alignItems: 'center', marginBottom: 1 }}>
        <CalendarMonthOutlinedIcon sx={{ fontSize: 'x-large', marginRight: '5px' }} />
        <Typography>{date}</Typography>
      </Box>

      {/* List of Classes */}
      <List>
        {classItems.map((classItem) => (
          <ListItem sx={{ px: 1 }} key={classItem.id}>
            <ListItemText primary={
              <Grid container alignItems="center" justifyContent="space-between" spacing={2}>

                {/* Date and Booking Numbers */}
                <Grid item xs={3} sm={3}>
                  <Box display="flex" flexDirection="column" alignItems="center">
                    {/* Time */}
                    <Typography variant="subtitle2">
                      {showFriendlyTime(classItem.date, user?.timezone)}
                    </Typography>
                    {/* If class full show "Full" button, else show available spaces */}
                    {classItem.spaces_booked === classItem.event_limit ? (
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={() => handleViewAttendees(classItem.id.toString())}
                      >Full</Button>
                    ) : (
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => handleViewAttendees(classItem.id.toString())}
                        sx={{
                          boxShadow: 'none',
                          '&:hover': {
                            boxShadow: 'none',
                          },
                          '&:active': {
                            boxShadow: 'none',
                          },
                        }}>
                        {classItem.spaces_booked} / {classItem.event_limit}
                      </Button>
                    )}
                    {/* Series icon */}
                    {classItem.repeat_event_id && (
                      <Box mt={0.5}>
                        <EventRepeatOutlinedIcon fontSize="small" color="action" />
                      </Box>
                    )}
                  </Box>
                </Grid>

                {/* Class Name and Duration */}
                <Grid item xs={6} sm={6}>
                  <Box display="flex" alignItems="center">
                    <Typography variant="subtitle1" sx={{ mr: 0.5 }}>{classItem.name}</Typography>
                  </Box>
                  <Typography variant="subtitle2">{classItem.duration} Mins</Typography>
                </Grid>

                {/* Book Class Button */}
                <Grid item xs={3} sm={3} display="flex" justifyContent="center">
                  <Button
                    disabled={classItem.spaces_booked === classItem.event_limit}
                    variant="contained"
                    size="small"
                    onClick={() => navigate(`/bookclass/${classItem.id}`)}
                    sx={{ '&:hover': { cursor: 'pointer' } }}
                  >
                    Book
                  </Button>
                </Grid>

              </Grid>
            } />
          </ListItem>
        ))}
      </List>

      {/* Dialog to show class attendees */}
      <AttendeesClassList
        open={!!selectedClassId}
        onClose={handleClose}
        attendees={attendees || []}
        isAttendeesLoading={isAttendeesLoading}
      />

    </Fragment>
  );
}