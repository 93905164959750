import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import { User, SignInCredentials } from '../types/types';

interface UserContextProps {
    user: User | null;
    isUserLoading: boolean;
    signIn: (credentials: SignInCredentials) => Promise<void>;
    signOut: () => void;
    updateUserData: (updatedUserData: User) => void;
  }

const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<User | null>(() => {
      // Initialize user state from localStorage if available
      const storedUser = localStorage.getItem('userData');
      return storedUser ? JSON.parse(storedUser) : null;
    });

    const [isUserLoading, setIsUserLoading] = useState<boolean>(true);
  
    useEffect(() => {
        const storedUser = localStorage.getItem('userData');
        if (storedUser) {
          setUser(JSON.parse(storedUser));
        }
        setIsUserLoading(false);
      }, []);

    const signIn = async (credentials: SignInCredentials): Promise<void> => {
      setIsUserLoading(true);
      const url = `${import.meta.env.VITE_API_ENDPOINT}/auth/api/login`;
      const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      });
      const jsonData = await response.json();
  
      if (!response.ok) {
        setIsUserLoading(false);
        throw new Error(JSON.stringify({
          message: jsonData.error.message,
          subMessage: jsonData.error.subMessage,
        }));
      }
  
      localStorage.setItem('userData', JSON.stringify(jsonData.user));
      setUser(jsonData.user);
      setIsUserLoading(false);
    };
  
    const signOut = () => {
      localStorage.removeItem('userData');
      setUser(null);
    };
  
    const updateUserData = (updatedUserData: User) => {
      setUser(updatedUserData);
      localStorage.setItem('userData', JSON.stringify(updatedUserData));
    };
    
    return (
      <UserContext.Provider value={{ user, isUserLoading, signIn, signOut, updateUserData }}>
        {children}
      </UserContext.Provider>
    );
  };
  
  export const useUser = (): UserContextProps => {
    const context = useContext(UserContext);
    if (context === undefined) {
      throw new Error('useUser must be used within a UserProvider');
    }
    return context;
  };
